
import React, { useContext, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { UserContext } from './login/UserContext';
import FetchProjectsComponent from './pages/Dashboard';
import ProjectDetailPage from './pages/ProjectDetailPage';
import GoogleIdentityLogin from './pages/GoogleLogin';
import { useLocation } from 'react-router-dom';
import CreateProjectPage from './pages/Create-Project';
import TeamFormComponent from './pages/TeamFormComponent';
import InternalTeamPage from './pages/InternalTeamPage';

const AppRoutes = () => {
  const { isAuthenticated } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && location.pathname === '/login') {
      navigate('/');
    } else if (!isAuthenticated && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [isAuthenticated, navigate, location.pathname]);

  return (
    <Routes>
      <Route path="/login" exact element={<GoogleIdentityLogin />} />
      <Route path="/project/:id" element={<ProjectDetailPage />} />
      <Route path="/team" element={<InternalTeamPage />} />
      <Route path="/create-project" element={<CreateProjectPage />} />
      <Route path="/" exact element={<FetchProjectsComponent />} />
    </Routes>
  );
};

export default AppRoutes;