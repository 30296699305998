import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ProjectService from "../services/ProjectService";
import financeService from "../services/FinanceService";
import qsnoimage from "../assets/noimage.png";
import googleDriveService from "../services/GoogleDriveService";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import swal from "sweetalert";
import GoogleDriveTab from "../components/ProjectDetailsTabs/GoogleDriveTabs";
import ProjectDetailsInfo from "../components/ProjectDetailsComponent/ProjectMainInfo";
import ProjectTabsHandler from "../components/ProjectDetailsComponent/ProjectTabsHandler";
import loader from "../assets/images/loading.gif";
import Swal from "sweetalert2";

const ProjectDetailPage = () => {
  const { id } = useParams();
  const [projectDetail, setProjectDetail] = useState(null);
  const [sheetIdInitial, setSheetIdInitial] = useState(null);
  const [sheetData, setSheetData] = useState({
    grossMarginPercentage: "#REF!",
    revenue: "0",
    expenses: "#REF!",
    totalCost: "#REF!",
    grossMargin: "#REF!",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const folder_id = projectDetail?.folderId;

  useEffect(() => {
    setLoading(true);
    ProjectService.findOne(id)
      .then((resp) => {
        setProjectDetail(resp.data);
        setLoading(false);
        localStorage.setItem("projectData", JSON.stringify(resp?.data));
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [id]);

  const handleEdit = () => {
    console.log("projectDetail", projectDetail);
    navigate("/create-project", { state: { projectDetail } });
  };

  const handleTabChange = (event, newValue) => {
    // console.log("Tab Changed", newValue);
    setSelectedTab(newValue);
  };

  const handleTabMobileChange = (event) => {
    setSelectedTab(event.target.value);
  };

  const handleFolderShare = () => {
    swal("Folder Shared", "Folder has been shared with the team", "success");
  };
  const handleFolderClick = (folder_id) => {
    // console.log("Folder Clicked", folder_id);
    const url = `https://drive.google.com/drive/folders/${folder_id}`;
    window.open(url, "_blank");
  };

  const handlePublishProject = () => {
    swal("Project Published", "success");
  };

  const allTeamMembers = [
    ...(projectDetail?.internalTeam || []),
    ...(projectDetail?.externalTeam || []),
    ...(projectDetail?.subcontractors || []),
  ];

  const uniqueTeamMembers = allTeamMembers.filter(
    (member, index, self) =>
      index === self.findIndex((m) => m.name === member.name)
  );

  const externalTeams = [
    ...(projectDetail?.consultants || []),
    ...(projectDetail?.externalTeam || []),
    ...(projectDetail?.collaborators || []),
    ...(projectDetail?.subcontractors || []),
  ];

  const allInternalTeam = uniqueTeamMembers.filter((member) =>
    projectDetail.internalTeam.some((internal) => 
      internal.name === member.name && internal.status !== 'inactive'
    )
  );

  const allExternalTeam = uniqueTeamMembers.filter((member) =>
    externalTeams.some((external) => 
      external.name === member.name && external.status !== 'inactive' 
    )
  );
  useEffect(() => {
    // console.log("allExternalTeam", projectDetail?.subcontractors);
    const fetchFinanceSheetId = async () => {
      try {
        const sheetId = projectDetail?.sheet
          ? projectDetail.sheet[0].sheetId
          : null;
        if (sheetId) {
          setSheetIdInitial(sheetId);
          const response = await financeService.getValues(sheetId);
          // console.log("testing finance from project page", response.data);
          setSheetData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchFinanceSheetId();
  }, [projectDetail]);

  return (
    <div style={{ marginTop: "55px", marginLeft: "2.5rem" }}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={loader}
            alt="Loading..."
            style={{
              width: "80px",
              height: "80px",
            }}
          />
        </div>
      )}
      <ProjectDetailsInfo
        projectDetail={projectDetail}
        handleEdit={handleEdit}
        qsnoimage={qsnoimage}
      />
      <ProjectTabsHandler
        isMobile={isMobile}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        handleTabMobileChange={handleTabMobileChange}
        handleTabChange={handleTabChange}
        uniqueTeamMembers={uniqueTeamMembers}
        allInternalTeam={allInternalTeam}
        allExternalTeam={allExternalTeam}
        handleFolderClick={handleFolderClick}
        handleFolderShare={handleFolderShare}
        project_id={projectDetail?.project_id}
        projectDetail={projectDetail}
        editing={editing}
        setEditing={setEditing}
        sheetIdInitial={sheetIdInitial}
        setSheetIdInitial={setSheetIdInitial}
        sheetData={sheetData}
        setSheetData={setSheetData}
      />
      {/* {projectDetail !== null && projectDetail.publishproject === "n" && (
        <div>
          <button
            onClick={handlePublishProject}
            className="btn btn-primary float-md-end"
          >
            Publish Project
          </button>
          <button className="btn btn-secondary float-md-end">Home</button>
        </div>
      )} */}
    </div>
  );
};
export default ProjectDetailPage;
