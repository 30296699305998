import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:3000/", // Local Environment
  baseURL: "https://archives.quicksand.co.in/backend", // Production Environment
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('Axios response error:', error);
    return Promise.reject(error); 
  }
);

export default instance;
