import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Input, Chip, Grid, FormHelperText, FormControl } from "@mui/material";
import AddOrganizationComponent from "./AddOrganizationComponent";
import { getOrganization } from "../services/FormService";
import trashCan from "../assets/images/icons/trash.png";
import { colourStyles } from "./SelectStyles";
import Swal from "sweetalert2";

const ContractingEntityComponent = ({
  clients,
  setClients,
  contractorData,
  setContractorData,
  countries,
  setCountries,
  defaultLocation,
  setDefaultLocation,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isTextboxOpen, setIsTextboxOpen] = useState(false);
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [showNewButton, setShowNewButton] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await getOrganization();
        setClients(response.data);
      } catch (error) {
        console.log("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, [setClients]);

  useEffect(() => {
    if (selectedContractor) {
      setValue("selectedContractor", selectedContractor || []);
      setValue("name", selectedContractor.contact_name || "");
      setValue("email", selectedContractor.email || "");
      setValue("designation", selectedContractor.contact_designation || "");
      setValue("phoneNumber", selectedContractor.contact_number || "");
      setValue("country", selectedContractor.location || "");
    } else {
      reset({
        name: "",
        email: "",
        designation: "",
        phoneNumber: "",
        country: "",
      });
    }
  }, [selectedContractor, reset, setValue]);

  const handleAddChip = (data) => {
    if (!selectedContractor) return;

    const updatedChip = {
      ...selectedContractor,
      contact_name: data.name,
      contact_email: data.email,
      contact_designation: data.designation,
      contact_number: data.phoneNumber,
      contact_location: data.country,
    };

    const existingIndex = contractorData.findIndex(
      (chip) => chip === selectedContractor
    );

    if (existingIndex !== -1) {
      const updatedChips = [...contractorData];
      updatedChips[existingIndex] = updatedChip;
      setContractorData(updatedChips);
    } else {
      setContractorData([...contractorData, updatedChip]);
    }

    setSelectedContractor(null);
    setShowForm(false);
    reset();
  };

  const handleDeleteChip = (chipToDelete) => {
    Swal.fire({
      text: "Are you sure you want to remove the contractor?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",

      confirmButtonText: "Remove",
      customClass: {
        popup: "swal2-smaller-popup",
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setContractorData((chips) =>
          chips.filter((chip) => chip !== chipToDelete)
        );

        if (selectedContractor === chipToDelete) {
          setContractorData(null);
        }
      }
    });
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const handleTextboxOpen = () => {
    setIsTextboxOpen(true);
  };

  const handleTextboxClose = () => {
    setIsTextboxOpen(false);
  };

  const handleEditChip = (chipData) => {
    setSelectedContractor(chipData);
    setShowForm(true);
  };

  const handleNewButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  };

  const handleMenuOpen = () => {
    setShowNewButton(true);
    setIsTextboxOpen(true);
  };

  const handleMenuClose = () => {
    setShowNewButton(false);
    setTimeout(() => {
      setIsTextboxOpen(false);
    }, 500);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  return (
    <div>
      {contractorData.length > 0 &&
        contractorData.map((chip, index) => (
          <Chip
            key={index}
            style={{
              marginRight: "0.5rem",
              backgroundColor: "#efefef",
              borderRadius: "5px",
              fontFamily: "Poppins",
              fontWeight: 450,
              fontSize: "14px",
            }}
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: `Contracting Entity: ${
                    chip.client?.name
                      ? `<strong>${chip.client.name}</strong>`
                      : chip.client?.contractororganization
                      ? `<strong>${chip.client.contractororganization}</strong>`
                      : chip.name
                      ? `<strong>${chip.name}</strong>`
                      : chip.contractororganization
                      ? `<strong>${chip.contractororganization}</strong>`
                      : ""
                  }`,
                }}
              />
            }
            onDelete={() => handleDeleteChip(chip)}
            onClick={() => handleEditChip(chip)}
            deleteIcon={
              <img
                src={trashCan}
                alt="trash"
                style={{ width: "auto", height: "50%" }}
              />
            }
          />
        ))}
      {!showForm && (
        <h6
          className="btn"
          style={{
            backgroundColor: "#efefef",
            fontFamily: "Poppins",
            fontWeight: 600,
            color: "#7c7d83",
            fontSize: "14px",
            marginTop: "0.5rem",
          }}
          onClick={toggleFormVisibility}
        >
          {!showForm && "+ Contracting Entity"}
        </h6>
      )}
      {showForm && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormHelperText>Contracting Entity Name *</FormHelperText>
              <Controller
                name="selectedContractor"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <div style={{ position: "relative" }}>
                    <Select
                      {...field}
                      options={clients}
                      maxMenuHeight={160}
                      minMenuHeight={160}
                      getOptionLabel={(option) =>
                        option.name || option.organization
                      }
                      getOptionValue={(option) => option.slug}
                      onChange={(value) => {
                        field.onChange(value);
                        setSelectedContractor(value);
                      }}
                      onInputChange={handleInputChange}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      components={{ IndicatorSeparator: null }}
                      placeholder="Select a Contracting Entity"
                      value={selectedContractor}
                      styles={{
                        ...colourStyles,
                        option: (provided, state) => ({
                          ...provided,
                          paddingBottom: 0,
                          paddingTop: 7,
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          color: "black",
                          minWidth: "21rem",
                        }),
                      }}
                    />
                    {isTextboxOpen && inputValue === "" && (
                      <button
                        style={{
                          position: "absolute",
                          right: 3,
                          bottom: "-12rem",
                          zIndex: 999,
                          textDecoration: "none",
                          color: showNewButton ? "black" : "transparent",
                          cursor: "pointer",
                          border: "none",
                          background: "none",
                          padding: 0,
                          fontSize: "small",
                        }}
                        onClick={handleNewButtonClick}
                      >
                        +New
                      </button>
                    )}
                  </div>
                )}
                rules={{ required: true }}
              />
              {errors.selectedContractor && (
                <FormHelperText error>
                  Contractor selection is required
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <FormHelperText>Point of Contact *</FormHelperText>
              <Input
                placeholder="Enter Contact Name"
                type="text"
                {...register("name")}
                defaultValue=""
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{ border: "1px solid rgba(128, 128, 128, 0.5)" }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormHelperText>Designation *</FormHelperText>
              <Input
                placeholder="Designation of contact"
                type="text"
                {...register("designation")}
                defaultValue=""
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{ border: "1px solid rgba(128, 128, 128, 0.5)" }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormHelperText>Mail ID *</FormHelperText>
              <Input
                fullWidth
                placeholder="Enter E-mail ID"
                type="email"
                {...register("email")}
                defaultValue=""
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{ border: "1px solid rgba(128, 128, 128, 0.5)" }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormHelperText>Mobile Number</FormHelperText>
              <Input
                fullWidth
                type="tel"
                {...register("phoneNumber")}
                defaultValue=""
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{ border: "1px solid rgba(128, 128, 128, 0.5)" }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormHelperText>Location</FormHelperText>
              <Input
                fullWidth
                type="text"
                {...register("country")}
                defaultValue=""
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{ border: "1px solid rgba(128, 128, 128, 0.5)" }}
                margin="normal"
              />
            </Grid>
          </Grid>
          <br />
          <button
            className="btn float-end"
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={handleSubmit(handleAddChip)}
          >
            Add
          </button>
          <div
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={toggleFormVisibility}
          >
            Remove Contractor
          </div>
        </div>
      )}

      <AddOrganizationComponent
        showModal={showModal}
        setShowModal={setShowModal}
        setClients={setClients}
        countries={countries}
        setCountries={setCountries}
        defaultLocation={defaultLocation}
        setDefaultLocation={setDefaultLocation}
      />
    </div>
  );
};

export default ContractingEntityComponent;
