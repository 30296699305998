import React, { useState, useEffect } from "react";
import { UserContext } from "./UserContext";

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [userData, setUserData] = React.useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUser(token);
      setIsAuthenticated(true);
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("backupUserData", JSON.stringify(userData));
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        setIsAuthenticated,
        userData,
        setUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
