import React, { useState, useEffect } from "react";
import TeamFormComponent from "./TeamFormComponent";
import projectService from "../services/ProjectService";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  TextareaAutosize,
  Icon,
} from "@mui/material";

import swal from "sweetalert";
import loader from "../assets/images/loading.gif";
import Swal from "sweetalert2";
import { error } from "jquery";

const InternalTeamPage = ({ projectDetail }) => {
  // Variables for handling Team members
  const [internalTeam, setInternalTeam] = useState([]);
  const [externalTeam, setExternalTeam] = useState([]);

  // Assigning variables for Existing Project Data
  const formDataSess = localStorage.getItem("projectData");
  const formDataParsed = JSON.parse(formDataSess);
  const description = formDataParsed.description;
  const projectname = formDataParsed.projectname;
  const project_id = formDataParsed.core_id;
  const projectImage = formDataParsed.imageurl;
  const projectPartner = formDataParsed.principalclientform[0]; // This is Principal Client
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const editing = params.get("editing");

  // useEffect actions assigned below will run automatically when component mounts ans saves the respective roles to the state variables .
  // For Managers
  useEffect(() => {
    if (
      editing &&
      formDataParsed &&
      Array.isArray(formDataParsed.internalTeam)
    ) {
      const updatedTeam = formDataParsed.internalTeam.map((member) => {
        if (!member.slug || member.slug === "") {
          return {
            ...member,
          };
        }
        return member;
      });
      setInternalTeam(updatedTeam);
    }

    if (Array.isArray(formDataParsed.externalTeam)) {
      const externalMembers = formDataParsed.externalTeam.map(
        (externalMember) => {
          if (!externalMember.slug || externalMember.slug === "") {
            return {
              ...externalMember,
            };
          }
          return externalMember;
        }
      );
      setExternalTeam(externalMembers);
    }
  }, []);

  // This will run when the project is created for the first time  -- Setting all the new members.
  const handleCreateProject = async () => {
    try {
      setLoading(true);
      const modifiedData = {
        ...formDataParsed,
        // coleads: team,
        internalTeam: internalTeam,
        externalTeam: externalTeam,
      };
      const response = await projectService.postProject(modifiedData);
      // console.log("response", response);
      const newId = response.data.data._id;
      if (response.status === 200) {
        if (response.data.success) {
          Swal.fire({
            text: "Project created successfully!",
            icon: "success",
          });
          sessionStorage.removeItem("formData");
          setLoading(false);
          navigate(`/project/${newId}`);
        } else {
          setLoading(false);
          swal(`Unable to create Project! - ${response.data?.data?.name}`);
          sessionStorage.removeItem("formData");
          navigate("/");
        }
      } else {
        console.log("Error posting project:", response.data);
        // Will handle error later - not required right now
      }
    } catch (error) {
      console.error("Error posting project:", error);
    }
  };

  // This will run when the project team is updated -- Updating all the changes.
  const handleUpdateProject = async () => {
    try {
      setLoading(true);
      const modifiedData = {
        ...formDataParsed,
        internalTeam: internalTeam,
        externalTeam: externalTeam,
      };
      const response = await projectService.updateProject(modifiedData);
      // console.log("response", response);
      if (response.status === 200) {
        const newId = response.data.data.value._id;
        if (response.data.success) {
          Swal.fire({
            text: "Project updated successfully!",
            icon: "success",
          });
          sessionStorage.removeItem("formData");
          setLoading(false);
          navigate(`/project/${newId}`);
        } else {
          setLoading(false);
          swal(`Unable to create Project! - ${response.data?.data?.name}`);
          sessionStorage.removeItem("formData");
          navigate("/");
        }
      } else {
        console.log("Error posting project:", response.data);
        // Will handle error later - not required right now
      }
    } catch (error) {
      console.error("Error posting project:", error);
    }
  };
  const handleCancel = async () => {
    navigate("/");
  };

  const isLocalhostImage = (url) => {
    const localhostPattern = /^(http:\/\/localhost|http:\/\/127\.0\.0\.1)/;
    return localhostPattern.test(url);
  };

  return (
    <>
      <div>
        <br />
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(255, 255, 255, 0.8)",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={loader}
              alt="Loading..."
              style={{
                width: "80px",
                height: "80px",
              }}
            />
          </div>
        )}

        <TeamFormComponent
          projectname={projectname}
          description={description}
          projectImage={projectImage}
          projectPartner={projectPartner}
          internalTeam={internalTeam}
          setInternalTeam={setInternalTeam}
          externalTeam={externalTeam}
          setExternalTeam={setExternalTeam}
          project_id={project_id}
        />
      </div>

      <div className="footer">
        <div className="float-end" style={{ marginRight: "5rem" }}>
          <button
            onClick={handleCancel}
            className="btn"
            type="submit"
            style={{ backgroundColor: "#f2f2f2", marginRight: "0.3rem" }}
          >
            <h6
              style={{
                color: "#7a8093",
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Cancel
            </h6>
          </button>
          {editing ? (
            <button
              onClick={handleUpdateProject}
              className="btn btn"
              style={{
                backgroundColor: "#D9D9D9",

                border: 0,
              }}
            >
              <h6
                style={{
                  color: "#595a60",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Update
              </h6>
            </button>
          ) : (
            <button
              onClick={handleCreateProject}
              className="btn"
              style={{ backgroundColor: "#f2f2f2" }}
            >
              <h6
                style={{
                  color: "#595a60",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Create Project
              </h6>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default InternalTeamPage;
