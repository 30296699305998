import React, { useState, useEffect } from "react";
import ProjectService from "../services/ProjectService";

const SearchComponent = ({
  allProjects,
  setProjects,
  searchTerm,
  setSearchTerm,
}) => {
  useEffect(() => {
    if (searchTerm === "") {
      setProjects(allProjects);
    } else {
      setProjects(
        allProjects.filter((project) =>
          project?.projectname?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, allProjects, setProjects]);

  return (
    <div>
      <input
        style={{
          border: "1px solid transparent",
          backgroundColor: "rgb(248 247 247)",
          borderRadius: "0px",
          minWidth: "17rem",
          minHeight: "2rem",
        }}
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
};

export default SearchComponent;
