import React from "react";
import GanttChartComponent from "../../pages/gantChart";
import KeyLearning from "../key-learnings/keyLearning";
import FinanceComponent from "../ProjectDetailsComponent/FinanceComponent";
import AddIcon from "@mui/icons-material/Add";

const KeyLearningsTab = ({ project_id }) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <KeyLearning project_id={project_id} />
    </div>
  );
};

export default KeyLearningsTab;
