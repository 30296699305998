import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { getSectorofwork } from "../services/FormService";
import SectorForm from "./SectorOfWorkModalComponent";
import { colourStyles } from "./SelectStyles";

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{ transform: "scale(1.3)", marginRight: "10px" }}
      />
      <label>{props.label}</label>
    </components.Option>
  );
};
const CustomMultiValue = () => null;

const CustomValueContainer = ({ children, ...props }) => {
  const count = props.getValue().length;
  const customText =
    count === 1 ? `${count} sector selected` : `${count} sectors selected`;

  return (
    <components.ValueContainer {...props}>
      {count > 0 ? (
        <div style={{ marginRight: "10px" }}>{customText}</div>
      ) : null}
      {children}
    </components.ValueContainer>
  );
};

const CustomIndicatorSeparator = () => null;

const CustomDropdownIndicator = (props) => {
  const { isTextboxOpen } = props.selectProps;

  const handleButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    props.selectProps.onNewClick();
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <components.DropdownIndicator {...props}>
      <div style={{ display: "flex", alignItems: "center", zIndex: 999 }}>
        <div style={{ backgroundColor: "#f2f2f2" }}>
          {isTextboxOpen && (
            <IconButton
              onClick={handleButtonClick}
              onMouseDown={handleMouseDown}
              size="small"
            >
              <AddIcon style={{ fontSize: 18 }} />
            </IconButton>
          )}
        </div>
      </div>
    </components.DropdownIndicator>
  );
};

const CustomMultiValueRemove = () => null;

function SectorOfWorkSelect({
  selectedSector,
  setSelectedSector,
  sector,
  setSector,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isTextboxOpen, setIsTextboxOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showNewButton, setShowNewButton] = useState(false);

  useEffect(() => {
    getSectorofwork()
      .then((response) => {
        setSector(response.data);
      })
      .catch((error) => {
        console.log("Error fetching service types:", error);
      });
  }, []);

  // useEffect(() => {
  //   console.log("selectedSector", selectedSector);
  // }, [selectedSector]);

  const handleChange = (selectedOptions) => {
    // console.log("selectedOptions", selectedOptions);
    setSelectedSector(selectedOptions || []);
  };

  const handleNewClick = () => {
    setModalIsOpen(true);
  };

  const handleTextboxOpen = () => {
    setIsTextboxOpen(true);
  };

  const handleTextboxClose = () => {
    setIsTextboxOpen(false);
  };

  const mapSectorsToOptions = (sectors) => {
    return sectors.map((sec) => ({
      value: sec.slug,
      label: sec.name,
      ...sec,
    }));
  };

  const handleNewButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalIsOpen(true);
  };

  const handleMenuOpen = () => {
    setShowNewButton(true);
    setIsTextboxOpen(true);
  };

  const handleMenuClose = () => {
    setShowNewButton(false);
    setTimeout(() => {
      setIsTextboxOpen(false);
    }, 500);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  return (
    <div style={{ position: "relative" }}>
      <SectorForm
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        sector={sector}
        setSector={setSector}
      />
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      ></div>
      <Select
        isMulti
        options={mapSectorsToOptions(sector)}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.slug}
        value={selectedSector}
        maxMenuHeight={160}
        minMenuHeight={160}
        onMenuOpen={handleMenuOpen}
        onInputChange={handleInputChange}
        onMenuClose={handleMenuClose}
        onChange={handleChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: CustomOption,
          MultiValue: CustomMultiValue,
          ValueContainer: CustomValueContainer,
          MultiValueRemove: CustomMultiValueRemove,
          IndicatorSeparator: null,
        }}
        placeholder="Select Sector of Work"
        styles={colourStyles}
        onNewClick={handleNewClick}
        isClearable={false}
      />
      {isTextboxOpen && inputValue === "" && (
        <button
        style={{
          position: "absolute",
          right: 3,
          bottom: "-12rem",
          zIndex: 999,
          textDecoration: "none",
          fontFamily: "Poppins",
          fontSize: "small",
          color: showNewButton ? "black" : "transparent",
          cursor: "pointer",
          border: "none",
          background: "none",
          padding: 0,
        }}
          onClick={handleNewButtonClick}
        >
          +New
        </button>
      )}
    </div>
  );
}

export default SectorOfWorkSelect;
