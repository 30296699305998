import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import swal from "sweetalert";
import { postRole } from "../services/FormService";
import { getProjectRoles } from "../services/FormService";

function RoleForm({
  show,
  onHide,
  addRole,
  existingRoles,
  setExistingRoles,
  designations,
  setDesignations,
}) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [nameFound, setNameFound] = useState(false);

  useEffect(() => {
    getProjectRoles().then((response) => {
      // console.log("Project roles", response.data);
      setExistingRoles(response.data);
    });
  }, []);

  const checkRole = (event) => {
    const searchName = event.target.value
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-");

    const exists = existingRoles.some(
      (designation) => designation.slug === searchName
    );

    setNameFound(exists);
  };

  const onSubmit = (data) => {
    if (!nameFound) {
      const currentDateTime = new Date().toISOString();
      const slug = data?.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
      const roleObj = {
        role: data.name,
        slug,
        created_at: currentDateTime,
        updated_at: currentDateTime,
      };

      postRole(roleObj)
        .then((response) => {
          const requestData = JSON.parse(response.config.data);

          const postedRole = requestData;
          swal("Role added", "", "success");
          reset();
          setDesignations((prevDesignations) => [
            ...prevDesignations,
            postedRole,
          ]);
          addRole(postedRole);
          onHide();
        })
        .catch((error) => {
          console.error("Error adding role:", error);
          swal("Error adding role", "", "error");
        });
    }
  };

  const handleAddButtonClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Modal
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50rem",
        bgcolor: "background.paper",
        border: "1px solid #000",
        borderRadius: "10px",
        boxShadow: 24,
        p: 4,
      }}
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontFamily: "Poppins", fontWeight: "550" }}>
          Add New Role
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

          <ul style={{ fontFamily: "Poppins", fontSize: "13px" }}>
            <li>
              Please review the existing project roles before adding a new one.
            </li>
            <li>Make sure the 1st letter of every word is a capital letter</li>
            <li>
              Ensure that the new project role is unique and not a duplicate
            </li>
          </ul>
          <form>
            <TextField
              fullWidth
              size="small"
              label="Enter Project Role"
              type="text"
              name="name"
              onKeyUp={checkRole}
              error={errors?.name || nameFound}
              {...register("name", { required: true })}
            />
            {errors?.name && <div>Role name is required.</div>}
            {nameFound && <div>Role name already exists.</div>}
            <p></p>
            <TextField
              fullWidth
              size="small"
              label="Confirm Project Role"
              type="text"
              name="confirmname"
              error={
                errors?.confirmname || watch("name") !== watch("confirmname")
              }
              {...register("confirmname", { required: true })}
            />
            {errors?.confirmname && <div>Role name is required.</div>}
            {watch("name") !== watch("confirmname") && (
              <div>Role names do not match.</div>
            )}
          </form>
 
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-sm"
          onClick={handleAddButtonClick}
          disabled={nameFound}
        >
          Add
        </Button>
        <Button className="btn btn-sm" variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RoleForm;
