import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getLatestCurrencyValue } from "../services/UserService";

function CurrencyTextField({
  currency,
  handleCurrencyChange,
  budget,
  handleBudgetChange,
  convertedBudget,
  setConvertedBudget,
  conversionRate,
  setConversionRate,
}) {
  useEffect(() => {
    const fetchConversionRates = async () => {
      const rates = await getLatestCurrencyValue();
      const parsedRates = JSON.parse(rates.data);
      const usdToInr = parsedRates.rates.INR;
      const inrToUsd = 1 / usdToInr;
      setConversionRate({ USDToINR: usdToInr, INRToUSD: inrToUsd });
    };

    fetchConversionRates();
  }, []);

  useEffect(() => {
    if (currency === "USD $") {
      setConvertedBudget(budget * conversionRate.USDToINR);
    } else if (currency === "INR ₹") {
      setConvertedBudget(budget * conversionRate.INRToUSD);
    } else {
      setConvertedBudget(0);
    }
  }, [budget, currency, conversionRate]);

  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <Select
        value={currency}
        onChange={handleCurrencyChange}
        variant="outlined"
        style={{
          height: "2rem",
          fontFamily: "Poppins",
          fontSize: "11px",
          marginRight: "0.1rem",
        }}
      >
        <MenuItem
          style={{ fontFamily: "Poppins", fontSize: "11px" }}
          value="USD $"
        >
          $ USD
        </MenuItem>
        <MenuItem
          style={{ fontFamily: "Poppins", fontSize: "11px" }}
          value="INR ₹"
        >
          ₹ INR
        </MenuItem>
      </Select>
      <TextField
        placeholder="0"
        variant="outlined"
        type="number"
        required
        value={budget === 0 ? "" : budget}
        onChange={handleBudgetChange}
        fullWidth
        InputProps={{
          style: { height: "2rem", fontFamily: "Poppins", fontSize: "12px" },
        }}
      />
    </div>
  );
}

export default CurrencyTextField;
