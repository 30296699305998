import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import googleDriveService from "../services/GoogleDriveService";
import { Modal, Button } from "react-bootstrap";
import chatImage from "../assets/images/timeline_sheet1.png";
import { EditNote as EditNoteIcon } from "@mui/icons-material";
import EditNotePng from "../assets/images/icons/edit.png";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "40px",
  },
  "& .MuiInputLabel-root": {
    top: "-6px",
  },
});

const TimelineComponent = ({
  projectId,
  linkedTimelines,
  setLinkedTimelines,
  loading,
  setLoading,
  projectDetail,
  toggleProjectStatus,
  adminUserInTeam,
  project_closed,
}) => {
  const [showSetupFolder, setShowSetupFolder] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sheetUrl, setSheetUrl] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [editTimelineIndex, setEditTimelineIndex] = useState(null);

  // useEffect(() => {
  //   getLinkedTimelines();
  // }, [sheetUrl]);

  // const getLinkedTimelines = async () => {
  //   try {
  //     const response = await googleDriveService.getLinkedTimelines(projectId);
  //     setLinkedTimelines(response.data);
  //   } catch (error) {
  //     console.error("Error fetching linked timelines:", error);
  //   }
  // };

  const openSpreadsheetUrl = (index) => {
    if (linkedTimelines.length > 0) {
      const url = linkedTimelines[0].url;
      window.open(url, "_blank");
    }
  };
  const linkTimeline = async () => {
    try {
      const url = { url: sheetUrl };
     
      
      if (sheetUrl !== "") {
        if (isEdit && editTimelineIndex !== null) {
          const confirmResult = await Swal.fire({
            text: "Are you sure you want to update the project timeline?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes, update it!",
            cancelButtonText: "Cancel",
          });

          if (confirmResult.isConfirmed) {
            const response = await googleDriveService.updateLinkedTimeline(
              projectId,
              url
            );
            setLoading(true);
            setLinkedTimelines(response.data.linkedTimelines);
            setShowSetupFolder(false);
            setEditTimelineIndex(null);
            setIsEdit(false);
            setSheetUrl("");
          }
        } else {
          const response = await googleDriveService.linkTimelineSheets(
            projectId,
            url
          );
          setLinkedTimelines(response.data.linkedTimelines);
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Please enter the Url",
        });
      }

      setShowSetupFolder(false);
      setSheetUrl("");
      setLoading(false);
    } catch (error) {
      console.error("Error linking timeline:", error);
      setLoading(false);
      setSheetUrl("");
    } finally {
      setLoading(false);
      setSheetUrl("");
    }
  };
  return (
    <div>
      <div style={{ display: "inline" }}>
        <div className="section-subtitle2">
          <div className="section-subtitle2">
            {linkedTimelines && linkedTimelines.length !== 0 && (
              <p className="management-team-styling">
                Project Timeline (Gantt Chart)
                {adminUserInTeam && !project_closed && (
                  <img
                  onClick={() => {
                    setEditTimelineIndex(0);
                    setIsEdit(true);
                    setShowSetupFolder(true);
                  }}
                    src={EditNotePng}
                    style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                    alt="edit"
                  />
                )}
              </p>
            )}
            {linkedTimelines && linkedTimelines.length > 0 && (
              <div style={{ minWidth: "105%" }}>
                <div>
                  <img
                    className="img-fluid"
                    style={{ minWidth: "100%", cursor: "pointer" }}
                    src={chatImage}
                    alt="drive-img"
                    onClick={() => {
                      // console.log("linkedTimelines:", linkedTimelines);
                      openSpreadsheetUrl(0);
                    }}
                    data-toggle="tooltip"
                    title="Share with team"
                  />
                </div>
                {adminUserInTeam &&
                  projectDetail !== null &&
                  projectDetail.publishproject &&
                  projectDetail.publishproject === "y" &&
                  projectDetail.closeproject === "n" && (
                    <button
                      style={{
                        textDecoration: "underline",
                        fontStyle: "italic",
                        fontSize: "14px",
                        color: "#0D6DFD",
                        fontFamily: "Poppins",
                      }}
                      className="btn float-end"
                      onClick={toggleProjectStatus}
                    >
                      Close Project
                    </button>
                  )}
              </div>
            )}

            <div style={{ position: "relative", display: "inline-block" }}>
              {(!linkedTimelines || linkedTimelines.length === 0) && (
                <>
                  <p className="management-team-styling">
                    Project Timeline (Gantt Chart)
                  </p>

                  <strong>
                    <button
                      style={{
                        fontSize: "5rem",
                        fontWeight: "700",
                        position: "absolute",
                        top: "55%",
                        left: "23%",
                        transform: "translate(-150%, -50%)",
                        textDecoration: "none",
                        color: "grey",
                        zIndex: 1,
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowSetupFolder(true)}
                    >
                      +
                    </button>
                  </strong>

                  <img
                    className="img-fluid"
                    style={{
                      width: "30%",
                      height: "10%",
                      cursor: "pointer",
                      opacity: "50%",
                    }}
                    src={chatImage}
                    alt="drive-img"
                    onClick={() => setShowSetupFolder(true)}
                    data-toggle="tooltip"
                    title="Share with team"
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <Modal show={showSetupFolder} onHide={() => setShowSetupFolder(false)}>
          <Box
            sx={{
              position: "absolute",

              left: "50%",
              transform: "translate(-50%, 80%)",
              width: "55rem",
              bgcolor: "background.paper",
              border: "1px solid #000",
              borderRadius: "10px",
              boxShadow: 24,
              p: 2,
            }}
          >
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Link Gantt Charts
            </div>

            <p style={{ fontFamily: "Poppins", fontSize: "14px" }}>
              <br />
              1. Go to the existing timeline sheet and share access with
              <span style={{ color: "brown" }}>“archives@quicksand.co.in”</span>
              .
              <br />
              <br />
              2. Once shared, enter the URL of spreadsheet and press “Link”
            </p>

            <CustomTextField
              fullWidth
              placeholder="Paste Gantt Chart URL here"
              value={sheetUrl}
              onChange={(e) => setSheetUrl(e.target.value)}
              required
            />

            <div className="float-end" style={{ marginTop: "1rem" }}>
              <h6
                className="btn submit-btn "
                style={{
                  marginRight: "0.5rem",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "purple",
                }}
                onClick={linkTimeline}
              >
                {linkedTimelines?.length > 0 ? "Update" : "Link"}
              </h6>
              <h6
                onClick={() => setShowSetupFolder(false)}
                className="btn submit-btn "
                style={{
                  marginRight: "0.5rem",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Close
              </h6>
            </div>
          </Box>
        </Modal>
      </div>

      {loader && <div className="loader text-center">Loading...</div>}
    </div>
  );
};

export default TimelineComponent;
