import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { getIndustry, postIndustry } from "../services/FormService";
import swal from "sweetalert";

function IndustryForm({ isOpen, onClose, industry, setIndustry }) {
  const { register, handleSubmit, errors, watch, reset } = useForm();

  const [nameFound, setNameFound] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [industryAdded, setIndustryAdded] = useState(false);

  useEffect(() => {
    getIndustry()
      .then((response) => {
        setIndustry(response.data);
      })
      .catch((error) => {
        console.log("Error fetching service types:", error);
      });
  }, [industryAdded]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const checkIndustry = (event) => {
    const searchName = event.target.value
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-");
    setNameFound(industry.includes(searchName));
  };

  const onSubmit = (data) => {
    if (!nameFound) {
      const currentDateTime = new Date().toISOString();
      const slug = data?.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
      const industryObj = {
        slug,
        created_at: currentDateTime,
        updated_at: currentDateTime,
      };
      postIndustry({ ...data, ...industryObj }).then((response) => {
        // console.log("industry added", response);
        swal("industry added", "", "success");
        setIndustryAdded(true)
        reset();
        onClose();
      });
    }
  };

  const handleAddButtonClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: 600, padding: 2, bgcolor: "background.paper" }}>
          <h6>Tagging and Identification</h6>
          <br />
          <h5>Add Industry</h5>
          <form>
            <TextField
              label="Enter industry"
              type="text"
              name="name"
              onKeyUp={checkIndustry}
              size="small"
              sx={{
                minWidth: "60%",
              }}
              error={errors?.name || nameFound}
              {...register("name", { required: true })}
            />
            {errors?.name && <div>name is required.</div>}
            {nameFound && <div>name already exists.</div>}
            <div
              style={{
                marginTop: "0.5rem",
                color: "#757575",
                fontFamily: "Poppins",
                fontSize: "12px",
              }}
            >
              <ul>
                <li>Please ensure to type the name in long form.</li>
                <li>
                  Make sure the 1st letter of every word is a capital letter.
                </li>
                <li>
                  Ensure that the new industry is unique and not a duplicate.
                </li>
              </ul>
            </div>
            <TextField
              label="Re-enter industry"
              type="text"
              name="confirmname"
              size="small"
              sx={{
                minWidth: "60%",
              }}
              error={
                errors?.confirmname || watch("name") !== watch("confirmname")
              }
              {...register("confirmname", { required: true })}
            />
            {errors?.confirmname && <div>name is required.</div>}
            {watch("name") !== watch("confirmname") && (
              <div>names do not match.</div>
            )}
          </form>
          <button
            className="btn btn-sm btn-secondary float-end"
            variant="contained"
            onClick={onClose}
          >
            Close
          </button>
          <button
            style={{ marginRight: "0.3rem" }}
            className="btn btn-sm btn-primary float-end"
            variant="contained"
            onClick={handleAddButtonClick}
          >
            Add
          </button>
        </Box>
      </Modal>
    </div>
  );
}

export default IndustryForm;
