import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      fontSize: "32px",
      fontWeight: "550",
      color: "black",
      fontFamily: "Poppins",
      padding:0
    },
    "& .MuiInputBase-input::placeholder": {
      color: "black",
      opacity: 1,
    },
  },
});

const CustomTextField = ({ className, value, onChange }) => {
  const classes = useStyles();

  return (
    <TextField
      className={`${classes.root} transparent-border`}
      style={{ minWidth: "60rem" }}
      variant="outlined"
      placeholder="Enter Project Name *"
      value={value}
      onChange={onChange}
      required
    />
  );
};

export default CustomTextField;
