import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteForever } from "react-icons/md";
import { Modal, Button, Table } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import loader from "../../assets/images/loading.gif";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";

import {
  postKeyLearning,
  getKeyLearnings,
  updateKeyLearning,
  deleteKeyLearning,
} from "../../services/FormService";

const KeyLearning = ({ project_id }) => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [projectPhase, setProjectPhase] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keyLearnings, setKeyLearnings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchKeyLearnings();
  }, [loading]);

  const fetchKeyLearnings = () => {
    getKeyLearnings(project_id)
      .then((response) => {
        setKeyLearnings(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
    setEditIndex(null);
    setProjectPhase("");
    setTitle("");
    setDescription("");
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure you want to delete this?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",

      confirmButtonText: "Delete",
      customClass: {
        popup: "swal2-smaller-popup",
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        deleteKeyLearning(id)
          .then(() => fetchKeyLearnings())
          .catch(console.error)
          .finally(() => setLoading(false));
      }
    });
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditOpen(true);
    setProjectPhase(keyLearnings[index].projectPhase);
    setTitle(keyLearnings[index].title);
    setDescription(keyLearnings[index].description);
  };

  const handleSubmit = () => {
    setLoading(true);

    const data = {
      projectPhase,
      title,
      description,
      project_id,
    };
    if (title !== "" && projectPhase !== "") {
      if (editOpen) {
        updateKeyLearning({ ...data, _id: keyLearnings[editIndex]._id })
          .then(() => {
            handleClose();
            fetchKeyLearnings();
          })
          .catch(console.error)
          .finally(() => setLoading(false));
      } else {
        postKeyLearning(data)
          .then(() => {
            handleClose();
            fetchKeyLearnings();
          })
          .catch(console.error)
          .finally(() => setLoading(false));
      }
    } else {
      setLoading(false);
      Swal.fire({
        text: "Please fill in all the fields!",
        icon: "error",
      });
    }
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={loader}
            alt="Loading..."
            style={{ width: "80px", height: "80px" }}
          />
        </div>
      )}

      <div>
        <p style={{ color: "grey", fontFamily: "Poppins", fontSize: "14px" }}>
          This is the section that can be accessed by all Quicksand members to
          learn from the project.
          <br />
          <span style={{ textDecoration: "underline" }}>Note:</span> Key
          learnings must be added before the project can be closed.
        </p>
        {keyLearnings.length > 0 && (
          <Table striped bordered hover style={{ marginTop: "20px" }}>
            <thead>
              <tr>
                <th>Title of learning</th>
                <th>Project phase</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {keyLearnings.map((keyLearning, index) => (
                <tr key={index}>
                  <td>{keyLearning.title}</td>
                  <td>{keyLearning.projectPhase}</td>
                  <td>
                    <CiEdit
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() => handleEdit(index)}
                    />
                    <MdOutlineDeleteForever
                      onClick={() => handleDelete(keyLearning._id)}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <h5
          className="btn"
          onClick={handleOpen}
          style={{
            backgroundColor: "#f0f0f0",
            fontFamily: "Poppins",
            fontWeight: "600",
          }}
        >
          + Add
        </h5>
        <Modal
          show={open || editOpen}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{
            maxWidth: "90%",
            margin: "auto",
          }}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {editOpen ? "Edit Key Learning" : "Add Key Learning"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Project Phase</label>
            <input
              type="text"
              className="form-control"
              value={projectPhase}
              onChange={(e) => setProjectPhase(e.target.value)}
            />
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label>Description</label>
            <ReactQuill
              value={description}
              onChange={setDescription}
              className="custom-quill"
              theme="snow"
              placeholder="Enter Description"
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              {editOpen ? "Update" : "Done"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default KeyLearning;
