import React, { useState, useEffect } from "react";
import financeService from "../../services/FinanceService";
import excelSheet from "../../assets/images/excel-sheet.png";
import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import DescriptionIcon from "@mui/icons-material/Description";
import "bootstrap/dist/css/bootstrap.min.css";
import loader from "../../assets/images/loading.gif";
import Swal from "sweetalert2";
import { handleSheetOpen } from "../../utils/FinanceUtils";
import FinanceMilestones from "./FinanceMilestones";

function FinanceComponent({
  sheetIdInitial,
  setSheetIdInitial,
  sheetData,
  setSheetData,
}) {
  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const currency = projectData.currency;
  const [sheetForm, setSheetForm] = useState({
    showInput: false,
    sheetName: "",
  });
  const [loading, setLoading] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);

  const messages = [
    "Creating finance Sheet...",
    "Please bear with us...",
    "Adding required datas...",
    "Finishing up in a moment!",
  ];

  useEffect(() => {
    if (loading) {
      let timeout1, timeout2, timeout3;

      timeout1 = setTimeout(() => setMessageIndex(1), 3500);
      timeout2 = setTimeout(() => setMessageIndex(2), 6500);
      timeout2 = setTimeout(() => setMessageIndex(3), 9500);

      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
        clearTimeout(timeout3);
      };
    }
  }, [loading]);

  const handleSheetShare = () => {
    Swal.fire({
      text: "Are you sure you want to share the sheet?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",

      confirmButtonText: "Share!",
      customClass: {
        popup: "swal2-smaller-popup",
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: "Sheet has been shared",
          icon: "success",
        });
      }
    });
  };

  const createSheet = () => {
    if (!sheetIdInitial) {
      setLoading(true);
      const obj = {
        project_id: projectData.project_id,
        sheetName: sheetForm.sheetName,
      };
      // console.log("obj", obj);
      financeService
        .sheetCreate(obj)
        .then((resp) => {
          if (resp) {
            // console.log(
            //   "response from finance sheet creation",
            //   resp.data.sheet[0].sheetId
            // );
            setSheetIdInitial(resp.data.sheet[0].sheetId);
            setLoading(false);
            // Swal.fire message here
            localStorage.setItem("projectData", JSON.stringify(resp));
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    setSheetForm({ showInput: false, sheetName: "" });
  };

  const handleCancelCreate = () => {
    setSheetForm({ showInput: false, sheetName: "" });
  };

  const handleCreateSheetClick = () => {
    setSheetForm({ showInput: true, sheetName: "" });
  };

  return (
    <div style={{ width: "100%" }}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={loader}
            alt="Loading..."
            style={{ width: "80px", height: "80px" }}
          />
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                position: "absolute",
                top: "60%",
                left: "45%",
                transform: "translate(-50%, -50%)",
                zIndex: 9998,
                fontSize: "18px",
                fontWeight: "bold",
                color: "#333",
                textAlign: "center",
                display:
                  messageIndex === index || messageIndex === index + 1
                    ? "block"
                    : "none",
              }}
              className={`loading-text ${
                messageIndex > index ? "loading-text-exit" : ""
              }`}
            >
              {message}
            </div>
          ))}
        </div>
      )}

      {sheetIdInitial && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",

            marginTop: "1.5rem",
            alignItems: "center",
            marginBottom: "20px",
            width: "95%",
          }}
        >
          {[
            {
              label: "Gross Margin %",
              value: sheetData.grossMarginPercentage,
            },
            {
              label: "Revenue",
              value: `${currency === "INR ₹" ? "₹" : "$"} ${sheetData.revenue}`,
            },
            {
              label: "Expenses",
              value: `${currency === "INR ₹" ? "₹" : "$"} ${
                sheetData.expenses
              }`,
            },
            {
              label: "Total Cost",
              value: `${currency === "INR ₹" ? "₹" : "$"} ${
                sheetData.totalCost
              }`,
            },
            {
              label: "Gross Margin",
              value: `${currency === "INR ₹" ? "₹" : "$"} ${
                sheetData.grossMargin
              }`,
            },
          ].map((item, index) => (
            <div
              key={index}
              style={{
                flex: "1 1 18%",
                marginRight: "1rem",
                marginBottom: "1rem",
              }}
            >
              <h6>{item.label}:</h6>
              <h3
                style={{
                  minHeight: "3rem",
                  width: "100%",
                  backgroundColor: "#e4e4e4",
                  borderRadius: "5px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                {item.value}
              </h3>
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: !sheetIdInitial ? "column" : "row",
          marginTop: "20px",

          width: "95%",
        }}
      >
        <div
          style={{
            flex: "1",
            marginBottom: !sheetIdInitial ? "1rem" : "0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {sheetForm.showInput ? (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="text"
                  placeholder="Enter sheet name"
                  value={sheetForm.sheetName}
                  onChange={(e) =>
                    setSheetForm({ ...sheetForm, sheetName: e.target.value })
                  }
                  style={{
                    marginTop: "1rem",
                    width: "15rem",
                    padding: "5px",
                    borderRadius: "0.4rem",
                    border: "1px solid grey",
                  }}
                />
                <div style={{ marginTop: "1rem" }}>
                  <button
                    className="btn "
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "#F8F8F8",
                      fontWeight: "500",
                    }}
                    onClick={createSheet}
                  >
                    Create
                  </button>
                  <button
                    className="btn "
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "#F8F8F8",
                      fontWeight: "500",
                    }}
                    onClick={handleCancelCreate}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div style={{ fontWeight: "400", marginBottom: "1rem" }}>
              {sheetIdInitial ? (
                <>
                  <h6
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    <DescriptionIcon
                      fontSize="small"
                      style={{ color: "green" }}
                    />
                    Project Profitability Sheet{" "}
                    <ShareIcon fontSize="x-small" onClick={handleSheetShare} />
                  </h6>
                </>
              ) : (
                <>
                  <p>Add Profitability Sheet</p>{" "}
                  <AddIcon onClick={handleCreateSheetClick} />
                </>
              )}
            </div>
          )}

          {sheetIdInitial && (
            <img
              style={{ cursor: "pointer" }}
              onClick={() => handleSheetOpen(sheetIdInitial)}
              src={excelSheet}
              alt="no-sheet"
            />
          )}
        </div>
        {sheetIdInitial ? (
          <div style={{ flexGrow: 2, marginLeft: "1.5rem" }}>
            <FinanceMilestones projectData={projectData} />
          </div>
        ) : (
          <div style={{ flexGrow: 2 }}>
            <FinanceMilestones projectData={projectData} />
          </div>
        )}
      </div>
    </div>
  );
}

export default FinanceComponent;
