import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { getServicetype, postServiceType } from "../services/FormService";
import swal from "sweetalert";

function ServiceForm({ isOpen, onClose, services, setServices }) {
  const { register, handleSubmit, errors, watch, reset } = useForm();

  const [nameFound, setNameFound] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceAdded, setServiceAdded] = useState(false);

  useEffect(() => {
    getServicetype().then((response) => {
      setServices(response.data);
    });
  }, [serviceAdded]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const checkServices = (event) => {
    const searchName = event.target.value
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-");
    setNameFound(services.includes(searchName));
  };

  const onSubmit = (data) => {
    if (!nameFound) {
      const currentDateTime = new Date().toISOString();
      const slug = data?.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
      const servicesObj = {
        slug,
        created_at: currentDateTime,
        updated_at: currentDateTime,
      };
      postServiceType({ ...data, ...servicesObj })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            // console.log("Service added");
            swal("Service added", "", "success");
            setServiceAdded(true)
          } else {
            // console.log(response.data.data.name);
            swal(response.data.data.name, "", "error");
          }
          reset();
          onClose();
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          swal("An error occurred", "", "error");
        });
    }
  };

  const handleAddButtonClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: 600, padding: 2, bgcolor: "background.paper" }}>
          <h6>Tagging and Identification</h6>
          <br />
          <h5>Add Service Type</h5>

          <form>
            <TextField
              label="Enter Service"
              type="text"
              size="small"
              name="name"
              onKeyUp={checkServices}
              sx={{
                minWidth: "60%",
              }}
              error={errors?.name || nameFound}
              {...register("name", { required: true })}
            />
            {errors?.name && (
              <div
                style={{
                  color: "#757575",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                }}
              >
                name is required.
              </div>
            )}
            {nameFound && (
              <div
                style={{
                  color: "#757575",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                }}
              >
                name already exists.
              </div>
            )}
            <div
              style={{
                marginTop: "0.5rem",
                color: "#757575",
                fontFamily: "Poppins",
                fontSize: "12px",
              }}
            >
              <ul>
                <li>Please ensure to type the name in long form.</li>
                <li>
                  Make sure the 1st letter of every word is a capital letter.
                </li>
                <li>
                  Ensure that the new service is unique and not a
                  duplicate.
                </li>
              </ul>
            </div>
            <TextField
              label="Re-enter Service"
              type="text"
              size="small"
              sx={{
                minWidth: "60%",
              }}
              name="confirmname"
              error={
                errors?.confirmname || watch("name") !== watch("confirmname")
              }
              {...register("confirmname", { required: true })}
            />
            {errors?.confirmname && <div>name is required.</div>}
            {watch("confirmname") !== "" &&
              watch("name") !== watch("confirmname") && (
                <div>names do not match.</div>
              )}
          </form>
          <button
            className="btn btn-sm btn-secondary float-end"
            variant="contained"
            onClick={onClose}
          >
            Close
          </button>
          <button
            style={{ marginRight: "0.3rem" }}
            className="btn btn-sm btn-primary float-end"
            variant="contained"
            onClick={handleAddButtonClick}
          >
            Add
          </button>
        </Box>
      </Modal>
    </div>
  );
}

export default ServiceForm;
