import React, { useContext } from "react";
import FetchProjectsComponent from "../hooks/FetchProjects";
import { UserContext } from "../login/UserContext";
import { findOneGoogleUser } from "../services/UserService";

const Dashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const user_band = localStorage.getItem("band");
  const user_role = localStorage.getItem("role_type");
  const user_depart = localStorage.getItem("department");
  const user_Id = localStorage.getItem("userId");
  const user_email = localStorage.getItem("userEmail");

  React.useEffect(() => {
    if (user_Id) {
      // console.log("user_band - ", user_band);
      // console.log("user_role - ", user_role);
      // console.log("user_depart - ", user_depart);
      // console.log("user_Id - ", user_Id);
      // console.log("user_email - ", user_email);
      setIsLoggedIn(true);
    }
  }, [user_Id]);

  return (
    <div>
      <div >
        {isLoggedIn && <FetchProjectsComponent />}
      </div>
    </div>
  );
};

export default Dashboard;
