import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import { getLocation } from "../services/FormService";
import LocationModalComponent from "./LocationModalComponent";
import { colourStyles } from "./SelectStyles";

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{ transform: "scale(1.3)", marginRight: "10px" }}
      />
      <label>{props.label}</label>
    </components.Option>
  );
};

const CustomMultiValue = () => null;

const CustomValueContainer = ({ children, ...props }) => {
  const count = props.getValue().length;
  const customText =
    count === 1 ? `${count} location selected` : `${count} locations selected`;

  return (
    <components.ValueContainer {...props}>
      {count > 0 ? (
        <div style={{ marginRight: "10px" }}>{customText}</div>
      ) : null}
      {children}
    </components.ValueContainer>
  );
};

const CustomMultiValueRemove = () => null;

function LocationSelect({
  locations,
  setLocations,
  selectedLocations,
  setSelectedLocations,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isTextboxOpen, setIsTextboxOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showNewButton, setShowNewButton] = useState(false);

  useEffect(() => {
    getLocation()
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        console.log("Error fetching locations:", error);
      });
  }, []);


  const handleTextboxOpen = () => {
    setIsTextboxOpen(true);
  };

  const handleTextboxClose = () => {
    setIsTextboxOpen(false);
  };

  const handleChange = (selectedOptions) => {
    // console.log("selectedOptions", selectedOptions);
    setSelectedLocations(selectedOptions || []);
  };

  const mapLocationsToOptions = (locations) => {
    return locations.map((location) => ({
      value: location._id,
      label: location.location,
      ...location,
    }));
  };

  const handleNewButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalIsOpen(true);
  };

  const handleMenuOpen = () => {
    setShowNewButton(true);
    setIsTextboxOpen(true);
  };

  const handleMenuClose = () => {
    setShowNewButton(false);
    setTimeout(() => {
      setIsTextboxOpen(false);
    }, 500);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  return (
    <div style={{ position: "relative" }}>
      <LocationModalComponent
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        locations={locations}
        setLocations={setLocations}
        selectedLocations={selectedLocations}
      />
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Select
          isMulti
          options={mapLocationsToOptions(locations)}
          getOptionLabel={(option) => option.location}
          getOptionValue={(option) => option._id}
          value={selectedLocations}
 
          onChange={handleChange}
          maxMenuHeight={160}
          minMenuHeight={160}
          onInputChange={handleInputChange}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option: CustomOption,
            MultiValue: CustomMultiValue,
            ValueContainer: CustomValueContainer,
            MultiValueRemove: CustomMultiValueRemove,
            IndicatorSeparator: null,
          }}
          placeholder="Select Location of Project"
          styles={{ ...colourStyles }}
          menuPortalTarget={document.body}
          isClearable={false}
        />
        {isTextboxOpen && inputValue === "" && (
          <button
            style={{
              position: "absolute",
              right: 3,
              bottom: "-12rem",
              zIndex: 999,
              textDecoration: "none",
              fontFamily: "Poppins",
              fontSize: "small",
              color: showNewButton ? "black" : "transparent",
              cursor: "pointer",
              border: "none",
              background: "none",
              padding: 0,
            }}
            onClick={handleNewButtonClick}
          >
            +New
          </button>
        )}
      </div>
    </div>
  );
}

export default LocationSelect;
