import instance from "../api/securedInstance";

class ProjectService {
  getHeaders() {
    const token = localStorage.getItem("token");
    return { Authorization: "Bearer " + token };
  }

  postProject(data) {

    console.log("data", data)
    console.log("data is a", typeof(data))
    return instance.post("project", data);
  }

  getProject() {
    return instance.get("get-project", { headers: this.getHeaders() });
  }

  getProjectIds() {
    return instance.get("get-projectids");
  }

  findOne(id) {
    return instance.get("projectbyid/" + id);
  }

  findProjectbyprojectId(project_id) {
    return instance.get("getproject-byprojectid/" + project_id);
  }

  findProject(data) {
    // const token = localStorage.getItem('token')
    const department = localStorage.getItem("department");
    const userId = localStorage.getItem("userId");
    const role_type = localStorage.getItem("role_type");
    const band = localStorage.getItem("band");
    // console.log("Band - ", band);

    const post_data = {
      userId: userId !== "undefined" && userId,
      role_type: role_type !== "undefined" && role_type,
      department: department !== "undefined" && department,
      band: band !== "undefined" && band,
    };
    return instance.post("fetch-projects", post_data);
  }

  updateProject(updProject) {
    console.log("updProject", updProject)
    return instance.put("update-project/" + updProject._id, updProject);
  }

  projectPublished(project) {
    return instance.post("project-published", project);
  }

  updateProjectByProjectId(updProject, id) {
    return instance.put("updateproject-projectId/" + id, updProject);
  }

  moveImage(data) {
    console.log("image data", data)
    return instance.post("move-image", data);
  }

  findImage(image) {
    return instance.post("find-image", image);
  }

  cancelImage(cancelimage) {
    let cimageobj = { cimage: cancelimage };
    return instance.post("cancel-image", cimageobj);
  }

  getProgress(projectId) {
    return instance.get("get-progress/" + projectId);
  }

  deleteProject(id) {
    return instance.delete("delete-project/" + id);
  }

  deleteTasks(project_id) {
    return instance.delete("delete-tasks/" + project_id);
  }

  resetTokens() {
    const token = localStorage.getItem("token");
    const headers = { Authorization: "Bearer " + token };
    return instance.get("generate-token", { headers });
  }

  datasync() {
    return instance.get("sync-data");
  }
}

const projectService = new ProjectService();
export default projectService;
