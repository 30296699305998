import React, { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../login/UserContext";
import { jwtDecode } from "jwt-decode";
import { findOneGoogleUser } from "../services/UserService";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import logoQS from "../assets/images/logo/logo-orange.png";

const useStyles = makeStyles({
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
});

const GoogleIdentityLogin = () => {
  const { setUser, setIsAuthenticated, setUserData } = useContext(UserContext);
  const classes = useStyles();
  useEffect(() => {
    window.handleCredentialResponse = function (response) {
      const idToken = response.credential;
      const decodedToken = jwtDecode(idToken);
      localStorage.setItem("token", idToken);
      localStorage.setItem("userEmail", decodedToken.email);
      localStorage.setItem("userName", decodedToken.name);
      localStorage.setItem("userPicture", decodedToken.picture);
      setUser(idToken);
      setIsAuthenticated(true);
      findOneGoogleUser(decodedToken.email)
        .then((resp) => {
          setUserData(resp?.data);
          localStorage.setItem("userData", JSON.stringify(resp?.data));
          localStorage.setItem("band", resp?.data?.band);
          localStorage.setItem("role_type", resp?.data?.employment_status.slug);
          localStorage.setItem("department", resp?.data?.department[0]?.slug);
          localStorage.setItem("userId", resp?.data?._id);
          localStorage.setItem("userEmail", resp?.data?.email);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id:
          "543648595225-am6hr1o5d2e3c277pr81mtlu0ngrlr06.apps.googleusercontent.com",
        callback: window.handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }
      );
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <div
        style={{
          flex: "1",
          backgroundColor: "#fed7c0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="text-center logo-vertical-center">
          <img className="img-fluid" src={logoQS} alt="logo" />
        </div>
      </div>

      <div
        style={{
          flex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{ color: "goldenrod" }}
          variant="h4"
          component="h3"
          gutterBottom
        >
          The Golden Archives
        </Typography>

        <Typography
          style={{ color: "grey", marginBottom:'2rem' }}
          variant="body1"
          component="body1"
          gutterBottom
        >
        Create, track and learn from the projects
        </Typography>

        <Typography variant="body1" gutterBottom></Typography>
        <div id="buttonDiv"></div>
      </div>
    </div>
  );
};

export default GoogleIdentityLogin;
