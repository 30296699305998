import React from "react";
import FinanceComponent from "../ProjectDetailsComponent/FinanceComponent";
import FinanceMilestones from "../ProjectDetailsComponent/FinanceMilestones";
import Grid from "@material-ui/core/Grid";

const FinanceTabs = ({
  sheetIdInitial,
  setSheetIdInitial,
  sheetData,
  setSheetData
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FinanceComponent         sheetIdInitial={sheetIdInitial}
        setSheetIdInitial={setSheetIdInitial}
        sheetData={sheetData}
        setSheetData={setSheetData} />
      </Grid>
    </Grid>
  );
};

export default FinanceTabs;
