import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../login/UserContext";
import LogoutIcon from "@mui/icons-material/Logout";
import swal from "sweetalert";
import qsLogo from "../assets/images/logo/favicon.png";
import loader from "../assets/images/loading.gif";
import { useNavigate } from "react-router-dom";
import projectService from "../services/ProjectService";

const NavbarComponent = () => {
  const { isAuthenticated, setIsAuthenticated } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    setIsAuthenticated(false);
    navigate("/");
    localStorage.clear();
    sessionStorage.clear();

    clearCookies();

    if ("caches" in window) {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );
    }
  };

  const clearCookies = () => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  };

  if (isAuthenticated === true && localStorage.getItem("token") === null) {
    handleLogout();
  }

  const userEmail = localStorage.getItem("userEmail");
  const userName = localStorage.getItem("userName");
  const userPicture = localStorage.getItem("userPicture");

  // useEffect(() => {
  //   console.log("userPicture: ", userPicture);
  // }, []);

  const handleDataSyncing = async () => {
    setLoading(true);
    const response = await projectService.datasync();
    // console.log("Response", response);
    if (
      (response.status === 200) &
      (response.data === "Data sync successfully")
    ) {
      swal(
        "Data Synced Successfully",
        "All your data has been synced",
        "success"
      );
      window.location.reload()
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={loader}
            alt="Loading..."
            style={{
              width: "80px",
              height: "80px",
            }}
          />
        </div>
      )}
      {isAuthenticated && (
        <nav className="navbar bg-light navbar-custom-styles">
          <a
            style={{ paddingLeft: "1rem" }}
            className="navbar-brand custom-brand-color"
            href="/"
          >
            <div
              style={{ fontFamily: "Poppins", fontSize: "18px" }}
              className="navbar-brand header-logo"
            >
              <img src={qsLogo} alt="favicon" /> The Golden Archives(BETA)
            </div>
          </a>
          {isAuthenticated && (
            <div className="d-flex align-items-center">
              <p className="mb-0 ml-2">{userName}</p>
              <img
                src={userPicture && userPicture}
                alt="User"
                className="rounded-circle"
                style={{ width: "30px", height: "30px" }}
              />
              {(userEmail === "bhavesh@quicksand.co.in" ||
                userEmail === "archives@quicksand.co.in") && (
                <button
                  className="btn btn-dark m-1"
                  onClick={handleDataSyncing}
                >
                  Data sync
                </button>
              )}

              <LogoutIcon
                style={{ marginLeft: "10px" }}
                onClick={handleLogout}
              />
            </div>
          )}
        </nav>
      )}
    </div>
  );
};

export default NavbarComponent;
