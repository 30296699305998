import { useState } from "react";
import addImage from "../assets/images/add1.png";

export const CreateProjectFormVariables = () => {
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedOrgEntity, setSelectedOrgEntity] = useState(["Quicksand"]);
  const [orgEntities, setOrgEntities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [sector, setSector] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [team, setTeam] = useState([]);
  const [managers, setManagers] = useState([]);
  const [tags, setTags] = useState("");
  const [selectedLocations, setSelectedLocations] = useState(locations || []);
  const [chipData, setChipData] = useState([]);
  const [budget, setBudget] = useState(0);
  const [clients, setClients] = useState([]);
  const [services, setServices] = useState([]);
  const [postId, setPostId] = useState([]);
  const [currency, setCurrency] = useState("USD $");
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState(addImage);
  const [principalClientsData, setPrincipalClientData] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState([]);

  return {
    projectName,
    setProjectName,
    description,
    setDescription,
    selectedClient,
    setSelectedClient,
    selectedContractor,
    setSelectedContractor,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    selectedOrgEntity,
    setSelectedOrgEntity,
    orgEntities,
    setOrgEntities,
    locations,
    setLocations,
    industry,
    setIndustry,
    selectedFile,
    setSelectedFile,
    selectedServices,
    setSelectedServices,
    selectedSector,
    setSelectedSector,
    sector,
    setSector,
    selectedIndustry,
    setSelectedIndustry,
    team,
    setTeam,
    managers,
    setManagers,
    tags,
    setTags,
    selectedLocations,
    setSelectedLocations,
    chipData,
    setChipData,
    budget,
    setBudget,
    clients,
    setClients,
    services,
    setServices,
    postId,
    setPostId,
    currency,
    setCurrency,
    loading,
    setLoading,
    imageName,
    setImageName,
    imageUrl,
    setImageUrl,
    principalClientsData,
    setPrincipalClientData,
    contractorData,
    setContractorData,
    countries,
    setCountries,
    defaultLocation,
    setDefaultLocation,
  };
};
