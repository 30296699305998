import instance from "../api/securedInstance";

class FinanceService {
  getHeaders() {
    const token = localStorage.getItem("token");
    return { Authorization: "Bearer " + token };
  }

  sheetCreate(obj) {
    return instance.post("create_google_sheet", obj, { headers: this.getHeaders() })
      .catch(error => {
        console.error('Error creating sheet:', error);
      });
  }

  shareSheet(sheetId, managersEmail) {
    const post_data = { 'sheetId': sheetId, 'email_ids': managersEmail };
    return instance.post("share-sheet", post_data, { headers: this.getHeaders() });
  }

  getValues(sheetId) {
    return instance.get(`get-sheet-values/${sheetId}`, { headers: this.getHeaders() });
  }
}

const financeService = new FinanceService();
export default financeService;