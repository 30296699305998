import instance from "../api/securedInstance";

export function getHeaders() {
  const token = sessionStorage.getItem("token");
  return { Authorization: "Bearer " + token };
}

export function getLocation() {
  return instance.get("get-location", { headers: getHeaders() });
}

export function getOrgsEntity() {
  return instance.get("get-org-entity", { headers: getHeaders() });
}

export function getCountries() {
  return instance.get("get-countries", { headers: getHeaders() });
}

export function getManager() {
  return instance.get("get-manager", { headers: getHeaders() });
}

export function getColeads() {
  return instance.get("get-coleads", { headers: getHeaders() });
}

export function getSeniorAssociates() {
  return instance.get("get-senior-associates", { headers: getHeaders() });
}

export function getAssociates() {
  return instance.get("get-associates", { headers: getHeaders() });
}

export function getSubContractors() {
  return instance.get("get-subcontractors", { headers: getHeaders() });
}

export function getCollaborators() {
  return instance.get("get-collaborators", { headers: getHeaders() });
}

export function getPartnersPrincipals() {
  return instance.get("get-partners-principals", { headers: getHeaders() });
}

export function getPartners() {
  return instance.get("get-partners", { headers: getHeaders() });
}

export function getPrincipals() {
  return instance.get("get-principals", { headers: getHeaders() });
}

export function getUsers() {
  return instance.get("users", { headers: getHeaders() });
}

export function getProjectRoles() {
  return instance.get("project_roles", { headers: getHeaders() });
}

export function getEmployees() {
  return instance.get("employees", { headers: getHeaders() });
}

export function getConsultants() {
  return instance.get("get-consultants", { headers: getHeaders() });
}

export function getOrganization() {
  return instance.get("get-principal-contractor", { headers: getHeaders() });
}

export function getIndustry() {
  return instance.get("get-industry", { headers: getHeaders() });
}

export function getSectorofwork() {
  return instance.get("get-sector-of-work", { headers: getHeaders() });
}

export function getServicetype() {
  return instance.get("get-service-type", { headers: getHeaders() });
}

export function getProficiencies() {
  return instance.get("get-proficiencies", { headers: getHeaders() });
}

export function getOnePrincipalContractor(selected_slug) {
  let slug = { slug: selected_slug };
  return instance.post("getone-principal-contractor", slug, {
    headers: getHeaders(),
  });
}

export function postOrganization(name) {
  return instance.post("post-principal-contractor", name, {
    headers: getHeaders(),
  });
}

export function postServiceType(name) {
  return instance.post("post-service-type", name, { headers: getHeaders() });
}

export function postConsultants(data) {
  return instance.post("post-consultants", data, { headers: getHeaders() });
}

export function postRole(data) {
  return instance.post("project_roles", data, { headers: getHeaders() });
}

export function employeeTeamStatus(data) {
  return instance.post("employee_status", data, { headers: getHeaders() });
}

export function postLocation(data) {
  return instance.post("post-location", data, { headers: getHeaders() });
}

export function postIndustry(data) {
  return instance.post("post-industry", data, { headers: getHeaders() });
}

export function postSectorofwork(data) {
  return instance.post("post-sector-of-work", data, { headers: getHeaders() });
}

export function postOrgEntity(data) {
  return instance.post("post-org-entity", data, { headers: getHeaders() });
}

export function postPartnersPrincipals(data) {
  return instance.post("post-partners-principals", data, {
    headers: getHeaders(),
  });
}

export function postManager(data) {
  return instance.post("post-manager", data, { headers: getHeaders() });
}

export function postAssociate(data) {
  return instance.post("post-associates", data, { headers: getHeaders() });
}

export function postSubContractors(data) {
  return instance.post("post-subcontractors", data, { headers: getHeaders() });
}

export function postCollaborators(data) {
  return instance.post("post-collaborators", data, { headers: getHeaders() });
}

export function postKeyLearning(data) {
  return instance.post("post-keylearning", data, { headers: getHeaders() });
}

export function getKeyLearnings(project_id) {
  let projectid = { project_id: project_id };
  return instance.post("get-keylearnings", projectid, {
    headers: getHeaders(),
  });
}

export function getOneKeyLearning(id) {
  let kl_id = { id: id };
  return instance.post("getone-keylearning", kl_id, { headers: getHeaders() });
}

export function updateKeyLearning(data) {
  return instance.put("update-keylearning/" + data._id, data, {
    headers: getHeaders(),
  });
}

export function deleteKeyLearning(id) {
  return instance.delete("delete-keylearning/" + id, { headers: getHeaders() });
}

export function getProjectPhase(project_id) {
  let projectid = { project_id: project_id };
  return instance.post("get-projectphase", projectid, {
    headers: getHeaders(),
  });
}

export function generateToken() {
  return instance.get("manual-reset", { headers: getHeaders() });
}

export function resetTokens() {
  return instance.get("generate-token", { headers: getHeaders() });
}
