import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import NavbarComponent from "./common/Navbar";
import { UserProvider } from "./login/UserProvider";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <UserProvider>
      <Router>
        <div>
          <NavbarComponent />

          <AppRoutes />
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
