import React, { useState, useEffect } from "react";
import googleDriveService from "../../services/GoogleDriveService";
import "bootstrap/dist/css/bootstrap.min.css";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import ExcelJS from "exceljs";

function FinanceMilestones({ projectData }) {
  const [milestones, setMilestones] = useState([]);
  const [milestoneTotal, setMilestoneTotal] = useState(0);

  const [newMilestone, setNewMilestone] = useState({
    description: "",
    due_date: "",
    amount: "",
    project_id: projectData.project_id,
  });
  const currency = projectData?.currency === "USD $" ? "USD" : "INR";
  const project_id = projectData.project_id;
  const project_name = projectData?.projectname;
  const budget = projectData.budget;
  const managers = projectData.managers;
  const partners = projectData.partners;
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    if (!projectData || !projectData.project_id) {
      console.log("No Project Data");
      return;
    }

    const fetchMilestones = async () => {
      try {
        const response = await googleDriveService.getMilestones(
          projectData.project_id
        );
        setMilestones(response.data);
      } catch (error) {
        console.error("Error fetching milestones:", error);
      }
    };

    fetchMilestones();
  }, [projectData.project_id]);

  useEffect(() => {
    if (!milestones || milestones.length === 0) {
      console.log("No Project Data");
      setMilestoneTotal(0);
      return;
    }

    const total = milestones.reduce((sum, milestone) => {
      const amount = parseFloat(milestone.amount) || 0;
      return sum + amount;
    }, 0);

    setMilestoneTotal(total);
  }, [milestones]);

  const handleAddMilestone = async () => {
    try {
      if (
        !newMilestone.description ||
        !newMilestone.amount ||
        !newMilestone.due_date
      ) {
        Swal.fire({
          icon: "error",

          text: "Please fill in all fields!",
        });
        return;
      }
      const totalAddedMilestone =
        milestoneTotal + parseFloat(newMilestone.amount);

      if (parseFloat(totalAddedMilestone) > parseFloat(budget)) {
        Swal.fire({
          icon: "error",

          text: "This amount will exceed the project budget!",
        });
        return;
      }

      const milestoneWithTeam = {
        ...newMilestone,
        managers: managers,
        partners: partners,
        budget: budget,
        project_id: project_id,
      };

      const response = await googleDriveService.addMilestone(milestoneWithTeam);

      setMilestones([...milestones, newMilestone]);

      setNewMilestone({ description: "", due_date: "", amount: "" });
    } catch (error) {
      console.error("Error adding milestone:", error);
      Swal.fire({
        icon: "error",

        text: "An error occurred while adding the milestone.",
      });
    }
  };

  const handleClearEntry = async () => {
    setNewMilestone({
      description: "",
      due_date: "",
      amount: "",
    });
  };

  const handleEditMilestone = (index) => {
    // console.log("index", index);
    setEditMode(true);
    setEditIndex(index);
    const milestoneToEdit = milestones[index];
    // console.log("milestoneToEdit", milestoneToEdit);
    setNewMilestone({
      description: milestoneToEdit.description,
      due_date: milestoneToEdit.due_date,
      amount: milestoneToEdit.amount,
      project_id: milestoneToEdit.project_id,
    });
  };
  const handleUpdateMilestone = async () => {
    try {
      if (
        !newMilestone.description ||
        !newMilestone.amount ||
        !newMilestone.due_date
      ) {
        Swal.fire({
          icon: "error",

          text: "Please fill in all fields!",
        });
        return;
      }

      const milestoneToUpdate = milestones[editIndex];
      if (!milestoneToUpdate) {
        console.error("Milestone to update not found.");
        return;
      }

      const totalUpdatedMilestone =
        milestoneTotal -
        parseFloat(milestoneToUpdate.amount) +
        parseFloat(newMilestone.amount);

      if (parseFloat(totalUpdatedMilestone) > parseFloat(budget)) {
        Swal.fire({
          icon: "error",
          text: "This amount will exceed the project budget!",
        });
        return;
      }

      const milestoneIdToUpdate = await milestones[editIndex]._id;
      // console.log("milestoneIdToUpdate", milestoneIdToUpdate);
      await googleDriveService.updateMilestone(
        milestoneIdToUpdate,
        newMilestone
      );

      const updatedMilestones = milestones.map((milestone, i) =>
        i === editIndex ? { ...milestone, ...newMilestone } : milestone
      );
      setMilestones(updatedMilestones);
      setEditMode(false);
      setEditIndex(null);
      setNewMilestone({ description: "", due_date: "", amount: "" });
    } catch (error) {
      console.error("Error updating milestone:", error);
    }
  };

  const handleDeleteMilestone = async (index) => {
    try {
      const willDelete = await Swal.fire({
        text: "Do you want to delete the milestone?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",

        confirmButtonText: "Delete",
        customClass: {
          popup: "swal2-smaller-popup",
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-secondary",
        },
        cancelButtonText: "Cancel",
      });

      if (willDelete.isConfirmed) {
        const milestoneId = milestones[index]._id;
        await googleDriveService.deleteMilestone(milestoneId);
        const updatedMilestones = milestones.filter((_, i) => i !== index);
        setMilestones(updatedMilestones);
      }
    } catch (error) {
      console.error("Error deleting milestone:", error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the milestone.",
        "error"
      );
    }
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Milestones");

    worksheet.columns = [
      { header: "DESCRIPTION", key: "description", width: 30 },
      { header: "DUE DATE", key: "due_date", width: 20 },
      { header: "AMOUNT", key: "amount", width: 15 },
      { header: "PROJECT BUDGET", key: "budget", width: 25 },
    ];

    milestones.forEach((milestone) => {
      worksheet.addRow({
        description: milestone.description,
        due_date: milestone.due_date,
        amount: milestone.amount,
        budget: milestone.budget,
      });
    });

    worksheet.getRow(1).font = { bold: true };

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${project_name}_finance_milestones_${project_id}.xlsx`;
    link.click();
  };

  return (
    <div style={{ width: "calc(100% - 10px)", marginRight: "0.5rem" }}>
      <div style={{ display: "flex" }}>
        <h6
          style={{
            marginBottom: "1rem",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "600",
            marginRight: "0.5rem",
          }}
        >
          Finance Milestones
        </h6>

        <DownloadIcon
          onClick={exportToExcel}
          style={{ color: "black", cursor: "pointer" }}
          fontSize="small"
        />
      </div>
      <div className="row">
        <div
          className="col-md-12"
          style={{ maxHeight: "250px", overflowY: "auto" }}
        >
          <table className="table table-striped table-bordered">
            <thead style={{ fontSize: "smaller" }}>
              <tr>
                <th style={{ width: "5%" }}>No.</th>
                <th style={{ width: "35%" }}>Milestone Description</th>
                <th style={{ width: "10%" }}>Due Date</th>
                <th style={{ width: "10%" }}>{`Amount(${
                  currency || "USD"
                })`}</th>
                <th style={{ width: "13%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {milestones?.map((milestone, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {editMode && editIndex === index ? (
                      <input
                        type="text"
                        value={newMilestone.description}
                        onChange={(e) =>
                          setNewMilestone({
                            ...newMilestone,
                            description: e.target.value,
                          })
                        }
                        className="form-control"
                        required
                        style={{ width: "100%" }}
                      />
                    ) : (
                      milestone.description
                    )}
                  </td>
                  <td>
                    {editMode && editIndex === index ? (
                      <input
                        type="date"
                        value={newMilestone.due_date}
                        onChange={(e) =>
                          setNewMilestone({
                            ...newMilestone,
                            due_date: e.target.value,
                          })
                        }
                        className="form-control"
                        required
                        style={{ width: "100%" }}
                      />
                    ) : (
                      milestone.due_date
                    )}
                  </td>
                  <td>
                    {editMode && editIndex === index ? (
                      <input
                        type="number"
                        value={newMilestone.amount}
                        onChange={(e) =>
                          setNewMilestone({
                            ...newMilestone,
                            amount: e.target.value,
                          })
                        }
                        className="form-control"
                        required
                        style={{ maxWidth: "100px" }}
                      />
                    ) : (
                      milestone.amount
                    )}
                  </td>
                  <td>
                    {editMode && editIndex === index ? (
                      <>
                        <button
                          onClick={handleUpdateMilestone}
                          style={{
                            backgroundColor: "#E4E4E4",
                            marginRight: "0.1rem",
                            fontFamily: "Poppins",
                            fontSize: "11.5px",
                            fontWeight: "600",
                            marginTop: "0.3rem",
                          }}
                          className="btn btn-sm"
                        >
                          Update
                        </button>
                        <button
                          onClick={() => {
                            setEditMode(false);
                            setEditIndex(null);
                            setNewMilestone({
                              description: "",
                              due_date: "",
                              amount: "",
                            });
                          }}
                          style={{
                            backgroundColor: "#E4E4E4",
                            marginRight: "0.1rem",
                            fontFamily: "Poppins",
                            fontSize: "11.5px",
                            fontWeight: "600",
                            marginTop: "0.3rem",
                          }}
                          className="btn btn-sm"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <CiEdit
                          style={{ marginRight: "1rem", cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => handleEditMilestone(index)}
                        />
                        <MdOutlineDeleteForever
                          style={{ cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => handleDeleteMilestone(index)}
                        />
                      </>
                    )}
                  </td>
                </tr>
              ))}
              {!editMode && (
                <tr>
                  <td>{milestones.length + 1}</td>
                  <td>
                    <input
                      type="text"
                      placeholder="Description"
                      value={newMilestone.description}
                      onChange={(e) =>
                        setNewMilestone({
                          ...newMilestone,
                          description: e.target.value,
                        })
                      }
                      className="form-control"
                      required
                      style={{ width: "100%" }}
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      value={newMilestone.due_date}
                      onChange={(e) =>
                        setNewMilestone({
                          ...newMilestone,
                          due_date: e.target.value,
                        })
                      }
                      className="form-control"
                      required
                      style={{ width: "100%" }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="Amount"
                      value={newMilestone.amount}
                      onChange={(e) =>
                        setNewMilestone({
                          ...newMilestone,
                          amount: e.target.value,
                        })
                      }
                      className="form-control"
                      required
                      style={{ width: "100%" }}
                    />
                  </td>
                  <td>
                    {newMilestone.description && (
                      <>
                        <button
                          style={{
                            backgroundColor: "#E4E4E4",
                            marginRight: "0.1rem",
                            fontFamily: "Poppins",
                            fontSize: "12.5px",
                            fontWeight: "600",
                            marginTop: "0.3rem",
                          }}
                          onClick={handleAddMilestone}
                          className="btn btn-sm "
                        >
                          Add
                        </button>
                        <button
                          style={{
                            backgroundColor: "#E4E4E4",
                            fontFamily: "Poppins",
                            fontSize: "12.5px",
                            fontWeight: "600",
                            marginTop: "0.3rem",
                          }}
                          onClick={() =>
                            setNewMilestone({
                              description: "",
                              due_date: "",
                              amount: "",
                            })
                          }
                          className="btn btn-sm "
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default FinanceMilestones;
