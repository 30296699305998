import React, { useState } from "react";
import LocationSelect from "../models/LocationSelector";
import ServiceSelect from "../models/ServiceTypeSelector";
import IndustrySelect from "../models/IndustrySelector";
import ClientFormComponent from "../models/ClientFormComponent";
import { Button, FormHelperText } from "@mui/material";
import TeamFormComponent from "./TeamFormComponent";
import TeamComponent from "./TeamComponent";
import { FormControl, useFormControlContext } from "@mui/base/FormControl";
import { Input, inputClasses } from "@mui/base/Input";
import { styled } from "@mui/system";
import clsx from "clsx";
import CreateProjectForm from "./CreateProjectForm";

const CreateProjectPage = () => {
  return (
    <div style={{ marginTop: "100px" }}>
      <CreateProjectForm />
    </div>
  );
};

export default CreateProjectPage;
