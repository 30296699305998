import React, { useState } from "react";
import { Grid, TextField, Input, Chip } from "@material-ui/core";

const OtherTagsInput = ({ label, value, onChange, chipData, setChipData }) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setChipData([
        ...chipData,
        { display: event.target.value, value: event.target.value },
      ]);
      const newEvent = {
        target: {
          name: event.target.name,
          value: event.target.value,
        },
      };
      // console.log("Chipdata", chipData);
      onChange(newEvent);
      event.target.value = "";
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  return (
    <div>
      <Input
        inputProps={{
          style: { fontSize: "0.9rem", fontFamily: "Poppins", padding: "10px" },
        }}
        style={{
          // border: "1px solid rgba(128, 128, 128, 0.5)",
          minWidth: "100%",
          maxBlockSize: "2rem",
          marginTop:'0.3rem',
          borderBottom: "transparent",
        }}
        label={label}
        variant="outlined"
        onKeyDown={handleKeyDown}
        placeholder="Enter a new tag"
        className="select-tags"
        required
      />
      <div style={{ marginTop: "1px" }}>
        {chipData.map((data, index) => (
          <Chip
            key={index}
            variant="filled"
            label={data.value}
            onDelete={handleDelete(data)}
            style={{
              borderRadius: "5px",
              backgroundColor: "#ebebeb",
              border: "1px solid transparent",
              color: "#7a8093",
              marginRight: "0.2rem",
              fontFamily: "Poppins",
              marginTop: "0.2rem",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default OtherTagsInput;
