import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "3rem",
    maxWidth: "60%",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",

    marginBottom: "1rem",
  },
  image: {
    width: "12rem",
    height: "10rem",
    marginRight: "2rem",
  },
  content: {
    flex: 1,
  },
  description: {
    margin: "1rem 0",
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "grey",
  },
  formGroup: {
    alignItems: "center",
    margin: "1rem",
  },
  autocomplete: {
    width: "24rem",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
  },
  chip: {
    flex: "1 1 calc(25% - 10px)",
    maxWidth: "calc(25% - 10px)",
  },
  offTheProjectChip: {
    opacity: "0.5",
  },
  addButton: {
    marginLeft: "1rem",
  },
  section: {
    // margin: "1rem 0",
  },
}));

export default useStyles;
