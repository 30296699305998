import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { getLocation, postLocation } from "../services/FormService";
import swal from "sweetalert";
import AddIcon from "@mui/icons-material/Add";

function LocationForm({
  isOpen,
  onClose,
  locations,
  setLocations,
  selectedLocations,
}) {
  const { register, handleSubmit, errors, watch, reset } = useForm();

  const [nameFound, setNameFound] = useState(false);
  const [locationAdded, setLocationAdded] = useState(false);

  useEffect(() => {
    getLocation()
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        console.log("Error fetching locations:", error);
      });
  }, [locationAdded]);

  const checkLocation = (event) => {
    const searchName = event.target.value
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-");
    setNameFound(locations.includes(searchName));
  };

  const onSubmit = (data) => {
    if (!nameFound) {
      const currentDateTime = new Date().toISOString();
      const slug = data.location.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
      const locationObj = {
        slug,
        created_at: currentDateTime,
        updated_at: currentDateTime,
      };
      postLocation({ ...data, ...locationObj }).then((response) => {
        // console.log("Location added", response.data);
        swal("Location added", "", "success");
        setLocationAdded(true)
        reset();
        onClose();
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: 600, padding: 2, bgcolor: "background.paper" }}>
        <h6 style={{fontFamily: "Poppins"}}>Logistical Information</h6>
        <br />
        <h5 style={{fontFamily: "Poppins"}}>Add New Location</h5>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Enter Location"
            type="text"
            size="small"
            style={{ minWidth: "20rem" }}
            name="location"
            onKeyUp={checkLocation}
            error={errors?.location || nameFound}
            {...register("location", { required: true })}
          />
          {errors?.location && <div>Location is required.</div>}
          {nameFound && <div>Location already exists.</div>}
          <div
            style={{
              marginTop: "0.5rem",
              color: "#757575",
              fontFamily: "Poppins",
              fontSize: "12px",
            }}
          >
            <ul>
              <li>Please ensure to type the name in long form.</li>
              <li>
                Make sure the 1st letter of every word is a capital letter.
              </li>
              <li>
                Ensure that the new location is unique and not a duplicate.
              </li>
            </ul>
          </div>
          <TextField
            label="Re-enter Location"
            type="text"
            size="small"
            name="confirmlocation"
            style={{ minWidth: "20rem", marginTop: "0.5rem" }}
            error={
              errors?.confirmlocation ||
              watch("location") !== watch("confirmlocation")
            }
            {...register("confirmlocation", { required: true })}
          />
          {errors?.confirmlocation && <div>Location is required.</div>}
          {watch("location") !== watch("confirmlocation") && (
            <div>Locations do not match.</div>
          )}
        </form>
        <div style={{ marginTop: "1rem" }}>
          <button
            className="btn btn-sm btn-secondary float-end"
            onClick={onClose}
          >
            Close
          </button>
          <button
            style={{ marginRight: "0.5rem" }}
            className="btn btn-sm btn-primary float-end"
            onClick={handleSubmit(onSubmit)}
          >
            Add
          </button>
        </div>
      </Box>
    </Modal>
  );
}

export default LocationForm;
