import React from "react";
import { useForm } from "react-hook-form";
import { Modal, TextField, Button, MenuItem } from "@mui/material";
import { postOrganization } from "../services/FormService";
import swal from "sweetalert";

const AddOrganizationComponent = ({
  showModal,
  setShowModal,
  setClients,
  countries,
  defaultLocation,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleAddOrganization = async (data) => {
    try {
      const response = await postOrganization(data);
      if (response.status === 200 && response.data.success === true) {
        setClients((prevClients) => [...prevClients, data]);
        reset();
        setShowModal(false);
        console.log("Organization added successfully:", response.data);
        swal("Success!", "Organization added successfully", "success");
      } else {
        console.error("Failed to add organization:", response.data);
        swal("Error!", "Failed to add organization", "error");
      }
    } catch (error) {
      console.error("Error adding organization:", error);
      swal("Error!", "Error adding organization", "error");
    }
  };

  const handleClear = () => {
    setShowModal(false);
    reset();
  };

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#f2f2f2",
          padding: "20px",
          borderRadius: "5px",
          width: "500px",
          fontSize: "13px",
        }}
      >
        <h5>Add New Organization</h5>
        <ul>
          <li>
            Please review the existing clients carefully before adding a new
            client.
          </li>
          <li>Please ensure to type the name in long form</li>
          <li>Make sure the 1st letter of every word is a capital letter</li>
          <li>
            Ensure that the new Organization is unique and not a duplicate
          </li>
        </ul>
        <div>
          <TextField
            label="Organization Name"
            type="text"
            {...register("name", { required: true })}
            error={!!errors.name}
            helperText={errors.name && "Organization name is required"}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Re-enter Organization"
            type="text"
            {...register("reEnterClient", { required: true })}
            error={!!errors.reEnterClient}
            helperText={
              errors.reEnterClient && "Please re-enter the client name"
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Location"
            select
            {...register("location", { required: true })}
            defaultValue={defaultLocation}
            error={!!errors.location}
            helperText={errors.location && "Location is required"}
            fullWidth
            margin="normal"
          >
            {countries.length > 0 &&
              countries.map((country) => (
                <MenuItem key={country._id} value={country.location}>
                  {country.location}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            label="Email ID"
            type="email"
            {...register("email", { required: true })}
            error={!!errors.email}
            helperText={errors.email && "Email is required"}
            fullWidth
            margin="normal"
          />
          <div className="float-end" style={{ display: "flex" }}>
            <button
              className="btn"
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                backgroundColor: "#D9D9D9", marginRight:'0.5rem'
              }}
              fullWidth
              onClick={handleSubmit(handleAddOrganization)}
            >
              Add
            </button>
            <button
              className="btn"
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                backgroundColor: "#D9D9D9",
              }}
              fullWidth
              onClick={handleClear}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddOrganizationComponent;
