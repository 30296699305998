import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Autocomplete,
  TextField,
  Button,
  Input,
  Chip,
  Grid,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import AddOrganizationComponent from "./AddOrganizationComponent";
import { getOrganization } from "../services/FormService";
import { FormHelperText } from "@material-ui/core";
import Select, { components } from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import trashCan from "../assets/images/icons/trash.png";
import { colourStyles } from "./SelectStyles";
import Swal from "sweetalert2";

const ClientSelectionComponent = ({
  clients,
  setClients,
  principalClientsData,
  setPrincipalClientData,
  countries,
  setCountries,
  defaultLocation,
  setDefaultLocation,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,

    formState: { errors },
    reset,
  } = useForm();

  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isTextboxOpen, setIsTextboxOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showNewButton, setShowNewButton] = useState(false);
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await getOrganization();
        setClients(response.data);
      } catch (error) {
        console.log("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, [setClients]);

  useEffect(() => {
    if (selectedClient) {
      setValue("selectedClient", selectedClient || []);
      setValue("name", selectedClient.contact_name || "");
      setValue("email", selectedClient.email || "");
      setValue("designation", selectedClient.contact_designation || "");
      setValue("phoneNumber", selectedClient.contact_number || "");
      setValue("country", selectedClient.location || "");
    } else {
      reset({
        name: "",
        email: "",
        designation: "",
        phoneNumber: "",
        country: "",
      });
    }
  }, [selectedClient, reset, setValue]);

  const handleAddChip = (data) => {
    if (!selectedClient) return;

    const updatedChip = {
      ...selectedClient,
      contact_name: data.name,
      contact_email: data.email,
      contact_designation: data.designation,
      contact_number: data.phoneNumber,
      contact_location: data.country,
    };

    const existingIndex = principalClientsData.findIndex(
      (chip) => chip === selectedClient
    );

    if (existingIndex !== -1) {
      const updatedChips = [...principalClientsData];
      updatedChips[existingIndex] = updatedChip;
      setPrincipalClientData(updatedChips);
    } else {
      setPrincipalClientData([...principalClientsData, updatedChip]);
    }

    setSelectedClient(null);
    setShowForm(false);
    reset();
  };

  const handleDeleteChip = (chipToDelete) => {
    Swal.fire({
      text: "Are you sure you want to remove the client?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",

      confirmButtonText: "Remove",
      customClass: {
        popup: "swal2-smaller-popup",
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setPrincipalClientData((chips) =>
          chips.filter((chip) => chip !== chipToDelete)
        );

        if (selectedClient === chipToDelete) {
          setSelectedClient(null);
        }
      }
    });
  };

  const handleClearClient = () => {
    setShowForm(false);
  };

  const toggleFormVisibility = () => {
    reset();
    setShowForm(!showForm);
  };
  const toggleVisibility = () => {
    setShowModal(!showModal);
  };

  const handleTextboxOpen = () => {
    setIsTextboxOpen(true);
  };

  const handleTextboxClose = () => {
    setIsTextboxOpen(false);
  };

  const handleEditChip = (chipData) => {
    setSelectedClient(chipData);
    setShowForm(true);
  };

  const options = clients.map((client) => ({
    label: client.name || client.organization,
    value: client.slug,
  }));

  const handleNewButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  };

  const handleMenuOpen = () => {
    setShowNewButton(true);
    setIsTextboxOpen(true);
  };

  const handleMenuClose = () => {
    setShowNewButton(false);
    setTimeout(() => {
      setIsTextboxOpen(false);
    }, 500);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  return (
    <div>
      {principalClientsData.map((chip, index) => (
        <Chip
          style={{
            marginRight: "0.5rem",
            backgroundColor: "#efefef",
            borderRadius: "5px",
            fontFamily: "Poppins",

            fontSize: "13.58px",
          }}
          key={index}
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: `Principal Client: <span style="color:black;'"> ${
                  chip.client?.name
                    ? `<strong>${chip.client.name}</strong>`
                    : chip.client?.organization
                    ? `<strong>${chip.client.organization}</strong>`
                    : chip.name
                    ? `<strong>${chip.name}</strong>`
                    : chip.organization
                    ? `<strong>${chip.organization}</strong>`
                    : ""
                } </span>`,
              }}
            />
          }
          onDelete={() => handleDeleteChip(chip)}
          onClick={() => handleEditChip(chip)}
          deleteIcon={
            <img
              src={trashCan}
              alt="trash"
              style={{ width: "auto", height: "50%" }}
            />
          }
        />
      ))}
      {!showForm && (
        <h5
          className="btn"
          style={{
            backgroundColor: "#efefef",
            fontFamily: "Poppins",
            fontWeight: 600,
            color: "#7c7d83",
            fontSize: "14px",
            marginTop: "0.5rem",
          }}
          onClick={toggleFormVisibility}
        >
          + Principal Client
        </h5>
      )}
      {showForm && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <FormHelperText>Principal Client Name *</FormHelperText>
                <Controller
                  name="selectedClient"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <div style={{ position: "relative" }}>
                      <Select
                        {...field}
                        options={clients}
                        onMenuOpen={handleMenuOpen}
                        onMenuClose={handleMenuClose}
                        maxMenuHeight={160}
                        minMenuHeight={160}
                        onInputChange={handleInputChange}
                        getOptionLabel={(option) =>
                          option.name || option.organization
                        }
                        getOptionValue={(option) => option.slug}
                        onChange={(option) => {
                          field.onChange(option);
                          setSelectedClient(option);
                        }}
                        components={{ IndicatorSeparator: null }}
                        placeholder="Select a Client"
                        styles={{
                          ...colourStyles,
                          option: (provided, state) => ({
                            ...provided,
                            paddingBottom: 0,
                            paddingTop: 7,
                            fontFamily: "Poppins",
                            fontSize: "13px",
                            color: "black",
                            minWidth: "21rem",
                          }),
                        }}
                      />
                      {isTextboxOpen && inputValue === "" && (
                        <button
                          style={{
                            position: "absolute",
                            right: 3,
                            bottom: "-12rem",
                            zIndex: 999,
                            textDecoration: "none",
                            color: showNewButton ? "black" : "transparent",
                            cursor: "pointer",
                            border: "none",
                            background: "none",
                            fontSize: "small",
                            padding: 0,
                          }}
                          onClick={handleNewButtonClick}
                        >
                          +New
                        </button>
                      )}
                    </div>
                  )}
                  rules={{ required: true }}
                />

                {errors.selectedClient && (
                  <FormHelperText error>
                    Client selection is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormHelperText>Point of Contact *</FormHelperText>
              <Input
                placeholder="Enter Contact Name"
                type="text"
                {...register("name")}
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{
                  border: "1px solid rgba(128, 128, 128, 0.5)",
                  borderBottom: "transparent",
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormHelperText>Designation *</FormHelperText>
              <Input
                placeholder="Designation of contact"
                type="text"
                {...register("designation")}
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{
                  border: "1px solid rgba(128, 128, 128, 0.5)",
                  borderBottom: "transparent",
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormHelperText>Mail ID *</FormHelperText>
              <Input
                fullWidth
                placeholder="Enter E-mail ID"
                type="email"
                {...register("email")}
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{
                  border: "1px solid rgba(128, 128, 128, 0.5)",
                  borderBottom: "transparent",
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormHelperText>Mobile Number</FormHelperText>
              <Input
                fullWidth
                type="tel"
                {...register("phoneNumber")}
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{
                  border: "1px solid rgba(128, 128, 128, 0.5)",
                  borderBottom: "transparent",
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormHelperText>Location</FormHelperText>
              <Input
                fullWidth
                type="select"
                {...register("country")}
                inputProps={{ style: { fontSize: "0.8rem", padding: "10px" } }}
                style={{
                  border: "1px solid rgba(128, 128, 128, 0.5)",
                  borderBottom: "transparent",
                }}
                margin="normal"
              />
            </Grid>
          </Grid>
          <br />
          <button
            className="btn float-end"
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={handleSubmit(handleAddChip)}
          >
            Add
          </button>

          <div
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleClearClient}
          >
            Remove Principal Client
          </div>
        </div>
      )}

      <AddOrganizationComponent
        showModal={showModal}
        setShowModal={setShowModal}
        setClients={setClients}
        countries={countries}
        setCountries={setCountries}
        defaultLocation={defaultLocation}
        setDefaultLocation={setDefaultLocation}
      />
    </div>
  );
};

export default ClientSelectionComponent;
