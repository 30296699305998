export const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    color: "black",
    fontFamily: "Poppins, sans-serif",
    fontSize: "small",
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#e1e4e8" : null,
    color: "black",
    fontFamily: "Poppins, sans-serif",
    fontSize: "small",
    whiteSpace: "nowrap",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderBottom: "25px solid #f2f2f2",
    paddingBottom: "0",
    overflowX: "hidden",
  }),
  menuList: (provided) => ({
    ...provided,

    overflowX: "hidden",
  }),
};

export const customStyles = {
  control: (styles) => ({
    ...styles,
    minHeight: "2.5rem",
    backgroundColor: "transparent",
    color: "black",
    fontFamily: "Poppins, sans-serif",
    fontSize: "small",
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#e1e4e8" : null,
    color: "black",
    fontFamily: "Poppins, sans-serif",
    fontSize: "small",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderBottom: "25px solid #f2f2f2",
    paddingBottom: "0",
  }),
};

export const customTeamStyles = {
  control: (styles) => ({
    ...styles,
    minHeight: "2.5rem",
    backgroundColor: "transparent",
    color: "black",
    fontFamily: "Poppins, sans-serif",
    fontSize: "small",
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#e1e4e8" : null,
    color: "black",
    fontFamily: "Poppins",
    fontSize: "13px",
    paddingBottom: 4,
  }),
  menu: (provided, state) => ({
    ...provided,
    borderBottom: "5px solid #f0f0f0",
  }),
};
