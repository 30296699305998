import React, { useEffect, useState } from "react";
import ProjectService from "../services/ProjectService";
import { Grid, Box } from "@material-ui/core";
import SearchComponent from "../common/SearchComponent";
import ProjectImgMediaCard from "../common/MediaCard";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import loader from "../assets/images/loading.gif";
import { Card, CardContent, Typography } from "@mui/material";

function FetchProjectsComponent() {
  const [publishedProjects, setPublishedProjects] = useState([]);
  const [unpublishedProjects, setUnpublishedProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allProjects, setAllProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    ProjectService.findProject()
      .then((response) => {
        const published = response.data.filter(
          (project) => project.publishproject === "y"
        );
        const unpublished = response.data.filter(
          (project) => project.publishproject !== "y"
        );

        // Add a placeholder project for the "Create a Project" card
        published.unshift({
          project_id: "create-project",
          placeholder: true,
        });

        setAllProjects(published);
        setPublishedProjects(published);
        setUnpublishedProjects(unpublished);

        localStorage.removeItem("projectData");
        sessionStorage.removeItem("formData");
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const projectDetail = (id) => {
    navigate(`/project/${id}`);
  };

  const createProjectPage = (id) => {
    navigate(`/create-project`);
  };

  return (
    <div style={{ marginLeft: "3rem", marginRight: "3rem" }}>
      <div style={{ marginTop: "80px", marginBottom: "60px" }}>
        <h2>My Projects</h2>
      </div>
      <div style={{ marginBottom: "15px" }}>
        <SearchComponent
          allProjects={allProjects}
          setProjects={setPublishedProjects}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={loader}
            alt="Loading..."
            style={{
              width: "80px",
              height: "80px",
            }}
          />
        </div>
      ) : (
        <>
          <Grid
            style={{
              marginTop: "1rem",
            }}
            container
            spacing={2}
          >
            {publishedProjects?.map((project) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={project.project_id}>
                {project.placeholder ? (
                  <Box
                    onClick={createProjectPage}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px dashed #ccc",
                      borderRadius: "10px",
                      height: "100%",
                      padding: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    <p style={{ color: "grey" }}>+ Create a New Project</p>
                  </Box>
                ) : (
                  <Box >
                    <ProjectImgMediaCard
                      image={project.imageurl}
                      title={project.projectname}
                      projectId={`ID: qs_${project.project_id}`}
                      clientName={project.principalclientform[0]?.organization || project.principalclientform[0]?.name}
                      onCardClick={() => projectDetail(project._id)}
                      locations={project.projectlocation}
                      status={project.closeproject}
                      published={project.projectpublished}
                      deleteId={project._id}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
          {unpublishedProjects?.length > 0 && searchTerm === "" && (
            <div>
              <div style={{ marginTop: "60px" }}>
                <h2>Unpublished Projects</h2>
              </div>
              <Grid
                style={{
                  marginTop: "20px",
                  paddingRight: "20px",
                  paddingLeft: "10px",
                }}
                container
                spacing={2}
              >
                {unpublishedProjects?.map((project) => (
                  <Grid
                    style={{ marginBottom: "0.5rem" }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={project.project_id}
                  >
                    <Box >
                      <ProjectImgMediaCard
                        image={project.imageurl}
                        title={project.projectname}
                        projectId={`ID: ${project.project_id}`}
                        clientName={project.principalclientform[0]?.organization || project.principalclientform[0]?.name}
                        onCardClick={() => projectDetail(project._id)}
                        locations={project.projectlocation}
                        status={project.closeproject}
                        deleteId={project._id}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FetchProjectsComponent;
